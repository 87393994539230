.floating-container1 {
  width: 100%;
  height: auto;
  display: flex;
  z-index: 9999;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.floating-container2 {
  gap: 73px;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-self: stretch;
  box-shadow: 0px 12px 32px -20px rgba(51, 11, 129, 1) ;
  align-items: center;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 60px;
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
}
 
.floating-texts {
  gap: 4px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.floating-text1 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700px;
  line-height: 28px;
  font-stretch: normal;
  letter-spacing: 0em;
}
 
.floating-text2 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 300px;
  line-height: 18px;
  font-stretch: normal;
  letter-spacing: 0.5px;
}
 
.floating-link {
  display: contents;
}
 
.floating-component {
  text-decoration: none;
}
 

 

 

 

 

 

 

 

 

 

 

 

 
@media(max-width: 550px) {
  .floating-container2 {
    align-self: center;
    box-shadow: none;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-oneandhalfunits);
    border-radius: var(--dl-radius-radius-radius2);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: unset;
    background-color: transparent;
  }
  .floating-texts {
    display: none;
  }
}
