.content-highlight-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.content-highlight-content {
  gap: 8px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 24px 32px;
  overflow: hidden;
  align-self: stretch;
  box-shadow: 0px 8px 24px -20px rgba(127, 127, 127, 0.5) ;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.30000001192092896);
}
 
.content-highlight-text1 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: Gotham;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
 
.content-highlight-text2 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 300px;
  line-height: 28px;
  font-stretch: normal;
  letter-spacing: 0.5px;
}
