.book-a-call-container1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/neon-back-1500w.png");
}
 
.book-a-call-content {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 1126px;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
 
.book-a-call-hero {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
 
.book-a-call-description {
  gap: var(--dl-space-space-twounits);
  width: 40%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.book-a-call-container2 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.book-a-call-text {
  color: rgba(0, 0, 0, 1);
  width: 100%;
  height: auto;
  font-size: 40px;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Black";
  font-weight: 700px;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.book-a-call-container3 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.book-a-call-iframe {
  width: 450px;
  height: 680px;
}
 
@media(max-width: 1200px) {
  .book-a-call-container1 {
    padding-left: 0px;
    padding-right: 0px;
  }
}
 
@media(max-width: 550px) {
  .book-a-call-content {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .book-a-call-hero {
    align-items: center;
    flex-direction: column;
  }
  .book-a-call-description {
    width: 100%;
  }
  .book-a-call-text {
    font-size: 36px;
  }
  .book-a-call-iframe {
    width: 100%;
    height: 680px;
    min-width: 375px;
  }
}
