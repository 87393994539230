.content-paragraph-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.content-paragraph-text {
  color: rgba(0, 0, 0, 1);
  width: 100%;
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 300px;
  line-height: 28px;
  font-stretch: normal;
  letter-spacing: 0.5px;
}
 
.content-paragraphroot-class-name {
  max-width: 800px;
}
 
.content-paragraphroot-class-name1 {
  max-width: 800px;
}
 
.content-paragraphroot-class-name3 {
  max-width: 800px;
}
