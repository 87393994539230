.old-home-container10 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/neon-back-1500w.png");
  background-position-y: 600px;
}
 
.old-home-video-container1 {
  width: 100%;
  height: 600px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
}
 
.old-home-video1 {
  width: 100%;
  height: auto;
}
 
.old-home-hero {
  gap: var(--dl-space-space-unit);
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
 
.old-home-container11 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-navlink10 {
  display: contents;
}
 
.old-home-component11 {
  text-decoration: none;
}
 
.old-home-description {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.old-home-title1 {
  width: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
 
.old-home-container12 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.old-home-text10 {
  color: rgb(0, 0, 0);
  width: 100%;
  height: auto;
  font-size: 72px;
  font-style: normal;
  text-align: center;
  font-family: "Gotham-Black";
  font-weight: 700px;
  line-height: 1;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-container13 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.old-home-text14 {
  color: rgb(0, 0, 0);
  width: 100%;
  height: auto;
  font-size: 24px;
  font-style: Book;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 300;
  line-height: 32px;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-buttons {
  gap: 16px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
 
.old-home-image1 {
  width: 40px;
  align-self: center;
  margin-top: -48px;
  object-fit: cover;
  margin-left: 50%;
}
 
.old-home-container14 {
  gap: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
 
.old-home-link1 {
  display: contents;
}
 
.old-home-component12 {
  text-decoration: none;
}
 
.old-home-navlink11 {
  display: contents;
}
 
.old-home-component13 {
  text-decoration: none;
}
 
.old-home-gradient {
  width: 100%;
  height: 62px;
  display: flex;
  overflow: hidden;
  position: relative;
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(254, 254, 254, 1) 100%);
  align-items: flex-start;
  flex-shrink: 0;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 1.00%,rgb(255, 255, 255) 100.00%);
}
 
.old-home-logo-container {
  gap: 24px;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  white-space: nowrap;
}
 
.old-home-logo-line {
  gap: 24px;
  display: flex;
  animation: slide 20s linear infinite;
  align-items: flex-start;
}
 
.old-home-logooptimism1 {
  width: 48px;
  height: 48px;
}
 
.old-home-logocelestia1 {
  width: 48px;
  height: 48px;
}
 
.old-home-logopolygon1 {
  width: 48px;
  height: 48px;
}
 
.old-home-logosub71 {
  width: 48px;
  height: 48px;
}
 
.old-home-logoradius1 {
  width: 48px;
  height: 48px;
}
 
.old-home-logonear1 {
  width: 48px;
  height: 48px;
}
 
.old-home-logoamlbot1 {
  width: 48px;
  height: 48px;
}
 
.old-home-logozkevm1 {
  width: 48px;
  height: 48px;
}
 
.old-home-logodora1 {
  width: 48px;
  height: 48px;
}
 
.old-home-logosafe1 {
  width: 48px;
  height: 48px;
}
 
.old-home-logooptimism2 {
  width: 48px;
  height: 48px;
}
 
.old-home-logocelestia2 {
  width: 48px;
  height: 48px;
}
 
.old-home-logopolygon2 {
  width: 48px;
  height: 48px;
}
 
.old-home-logosub72 {
  width: 48px;
  height: 48px;
}
 
.old-home-logoradius2 {
  width: 48px;
  height: 48px;
}
 
.old-home-logonear2 {
  width: 48px;
  height: 48px;
}
 
.old-home-logoamlbot2 {
  width: 48px;
  height: 48px;
}
 
.old-home-logozkevm2 {
  width: 48px;
  height: 48px;
}
 
.old-home-logodora2 {
  width: 48px;
  height: 48px;
}
 
.old-home-logosafe2 {
  width: 48px;
  height: 48px;
}
 
.old-home-logooptimism3 {
  width: 48px;
  height: 48px;
}
 
.old-home-logocelestia3 {
  width: 48px;
  height: 48px;
}
 
.old-home-logopolygon3 {
  width: 48px;
  height: 48px;
}
 
.old-home-logosub73 {
  width: 48px;
  height: 48px;
}
 
.old-home-logoradius3 {
  width: 48px;
  height: 48px;
}
 
.old-home-logonear3 {
  width: 48px;
  height: 48px;
}
 
.old-home-logoamlbot3 {
  width: 48px;
  height: 48px;
}
 
.old-home-logozkevm3 {
  width: 48px;
  height: 48px;
}
 
.old-home-logodora3 {
  width: 48px;
  height: 48px;
}
 
.old-home-logosafe3 {
  width: 48px;
  height: 48px;
}
 
.old-home-logooptimism4 {
  width: 48px;
  height: 48px;
}
 
.old-home-logocelestia4 {
  width: 48px;
  height: 48px;
}
 
.old-home-logopolygon4 {
  width: 48px;
  height: 48px;
}
 
.old-home-logosub74 {
  width: 48px;
  height: 48px;
}
 
.old-home-logoradius4 {
  width: 48px;
  height: 48px;
}
 
.old-home-logonear4 {
  width: 48px;
  height: 48px;
}
 
.old-home-logoamlbot4 {
  width: 48px;
  height: 48px;
}
 
.old-home-logozkevm4 {
  width: 48px;
  height: 48px;
}
 
.old-home-logodora4 {
  width: 48px;
  height: 48px;
}
 
.old-home-logosafe4 {
  width: 48px;
  height: 48px;
}
 
.old-home-logooptimism5 {
  width: 48px;
  height: 48px;
}
 
.old-home-logocelestia5 {
  width: 48px;
  height: 48px;
}
 
.old-home-logopolygon5 {
  width: 48px;
  height: 48px;
}
 
.old-home-logosub75 {
  width: 48px;
  height: 48px;
}
 
.old-home-logoradius5 {
  width: 48px;
  height: 48px;
}
 
.old-home-logonear5 {
  width: 48px;
  height: 48px;
}
 
.old-home-logoamlbot5 {
  width: 48px;
  height: 48px;
}
 
.old-home-logozkevm5 {
  width: 48px;
  height: 48px;
}
 
.old-home-logodora5 {
  width: 48px;
  height: 48px;
}
 
.old-home-logosafe5 {
  width: 48px;
  height: 48px;
}
 
.old-home-content1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 1126px;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: column;
}
 
.old-home-container15 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.old-home-container16 {
  gap: 24px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  backdrop-filter: blur(64px);
  justify-content: flex-end;
}
 
.old-home-content2 {
  gap: 24px;
  display: flex;
  padding: 32px 56px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: 0px 8px 24px -20px rgba(127, 127, 127, 0.5) ;
  align-items: center;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.30000001192092896);
}
 
.old-home-link2 {
  display: contents;
}
 
.old-home-component19 {
  text-decoration: none;
}
 
.old-home-video2 {
  gap: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
 
.old-home-play1 {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 82px;
  bottom: 0px;
  cursor: pointer;
  height: 82px;
  margin: auto;
  display: flex;
  z-index: 1;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 120px;
  background-color: rgba(255, 255, 255, 0.5);
}
 
.old-home-play2 {
  top: 15px;
  left: 21px;
  width: 40px;
  height: 40px;
  position: absolute;
}
 
.old-home-text17 {
  top: 53px;
  left: 28px;
  color: rgba(0, 0, 0, 1);
  height: auto;
  position: absolute;
  font-size: 12px;
  font-style: Book;
  text-align: center;
  font-family: "Gotham-Book";
  font-weight: 300;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-video-container2 {
  width: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-video3 {
  width: 100%;
  height: auto;
  max-width: 800px;
  border-radius: 29px;
}
 
.old-home-container17 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-container18 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-fourunits);
  align-items: center;
  flex-direction: row;
}
 
.old-home-navlink12 {
  display: contents;
}
 
.old-home-component22 {
  text-decoration: none;
}
 
.old-home-navlink13 {
  display: contents;
}
 
.old-home-component23 {
  text-decoration: none;
}
 
.old-home-navlink14 {
  display: contents;
}
 
.old-home-component24 {
  text-decoration: none;
}
 
.old-home-container19 {
  width: 100%;
  display: none;
  align-items: center;
  flex-direction: column;
}
 
.old-home-container20 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-fourunits);
  align-items: center;
  flex-direction: row;
}
 
.old-home-navlink15 {
  display: contents;
}
 
.old-home-component25 {
  text-decoration: none;
}
 
.old-home-navlink16 {
  display: contents;
}
 
.old-home-component26 {
  text-decoration: none;
}
 
.old-home-navlink17 {
  display: contents;
}
 
.old-home-component27 {
  text-decoration: none;
}
 
.old-home-container21 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-fourunits);
  align-items: center;
  flex-direction: row;
}
 
.old-home-navlink18 {
  display: contents;
}
 
.old-home-component28 {
  text-decoration: none;
}
 
.old-home-navlink19 {
  display: contents;
}
 
.old-home-component29 {
  text-decoration: none;
}
 
.old-home-navlink20 {
  display: contents;
}
 
.old-home-component30 {
  text-decoration: none;
}
 
.old-home-container22 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-fourunits);
  align-items: center;
  flex-direction: row;
}
 
.old-home-navlink21 {
  display: contents;
}
 
.old-home-component31 {
  text-decoration: none;
}
 
.old-home-navlink22 {
  display: contents;
}
 
.old-home-component32 {
  text-decoration: none;
}
 
.old-home-container23 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
 
.old-home-navlink23 {
  display: contents;
}
 
.old-home-component36 {
  text-decoration: none;
}
 
.old-home-container24 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
 
.old-home-navlink24 {
  display: contents;
}
 
.old-home-component40 {
  text-decoration: none;
}
 
.old-home-container25 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.old-home-container26 {
  gap: 24px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  backdrop-filter: blur(64px);
  justify-content: flex-end;
}
 
.old-home-content3 {
  gap: 24px;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  flex-wrap: wrap;
  box-shadow: 0px 8px 24px -20px rgba(127, 127, 127, 0.5) ;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 29px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: rgba(255, 255, 255, 0.30000001192092896);
}
 
.old-home-frame481909 {
  gap: 16px;
  width: auto;
  height: auto;
  display: flex;
  padding: 32px;
  overflow: hidden;
  box-shadow: 0px 16px 16px -18px rgba(0, 0, 0, 0.10000000149011612) ;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.old-home-text18 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190210 {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
 
.old-home-frame48190010 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logopolygon6 {
  width: 56px;
  height: 56px;
}
 
.old-home-text19 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190110 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logooptimism6 {
  width: 56px;
  height: 56px;
}
 
.old-home-text20 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190111 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logooptimism7 {
  width: 56px;
  height: 56px;
}
 
.old-home-text21 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190112 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logooptimism8 {
  width: 56px;
  height: 56px;
}
 
.old-home-text22 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190113 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logooptimism9 {
  width: 56px;
  height: 56px;
}
 
.old-home-text23 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame481906 {
  gap: 16px;
  width: auto;
  height: auto;
  display: flex;
  padding: 32px;
  overflow: hidden;
  box-shadow: 0px 16px 16px -18px rgba(0, 0, 0, 0.10000000149011612) ;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.old-home-text24 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190211 {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
 
.old-home-frame48190011 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logonear6 {
  width: 56px;
  height: 56px;
}
 
.old-home-text25 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190114 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logocelestia6 {
  width: 56px;
  height: 56px;
}
 
.old-home-text26 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190115 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logocelestia7 {
  width: 56px;
  height: 56px;
}
 
.old-home-text27 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame4819031 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logodomicon {
  width: 56px;
  height: 56px;
  display: flex;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 16px 16px -12px rgba(0, 0, 0, 0.10000000149011612) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.old-home-domicon1 {
  top: 0px;
  left: 0px;
  width: 56px;
  height: 56px;
  position: absolute;
  border-radius: 6px;
}
 
.old-home-text28 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame481908 {
  gap: 16px;
  width: auto;
  height: auto;
  display: flex;
  padding: 32px;
  overflow: hidden;
  box-shadow: 0px 16px 16px -18px rgba(0, 0, 0, 0.10000000149011612) ;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.old-home-text29 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190212 {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
 
.old-home-frame48190012 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logoamlbot6 {
  width: 56px;
  height: 56px;
}
 
.old-home-text30 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190116 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logogatenox {
  width: 56px;
  height: 56px;
  display: flex;
  position: relative;
  box-shadow: 0px 16px 16px -12px rgba(0, 0, 0, 0.10000000149011612) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.old-home-z50v-l-hlz400x40021 {
  top: 0px;
  left: 0px;
  width: 56px;
  height: 56px;
  position: absolute;
  border-radius: 6px;
}
 
.old-home-text31 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame481911 {
  gap: 16px;
  width: auto;
  height: auto;
  display: flex;
  padding: 32px;
  overflow: hidden;
  box-shadow: 0px 16px 16px -18px rgba(0, 0, 0, 0.10000000149011612) ;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.old-home-text32 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-container27 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.old-home-frame48190213 {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
 
.old-home-frame48190013 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logosafe6 {
  width: 56px;
  height: 56px;
}
 
.old-home-text33 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190117 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logobiconomy {
  width: 56px;
  height: 56px;
}
 
.old-home-text34 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190214 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logoden1 {
  width: 56px;
  height: 56px;
}
 
.old-home-text35 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame4819032 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logoden2 {
  width: 56px;
  height: 56px;
}
 
.old-home-text36 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190014 {
  gap: 16px;
  width: auto;
  height: auto;
  display: flex;
  padding: 32px;
  overflow: hidden;
  box-shadow: 0px 16px 16px -18px rgba(0, 0, 0, 0.10000000149011612) ;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.old-home-text37 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-container28 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
 
.old-home-frame48190215 {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
 
.old-home-frame48190015 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logoblockscout {
  width: 56px;
  height: 56px;
}
 
.old-home-text38 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190118 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logodora6 {
  width: 56px;
  height: 56px;
}
 
.old-home-text39 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190119 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logodora7 {
  width: 56px;
  height: 56px;
}
 
.old-home-text40 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame481905 {
  gap: 16px;
  width: auto;
  height: auto;
  display: flex;
  padding: 32px;
  overflow: hidden;
  box-shadow: 0px 16px 16px -18px rgba(0, 0, 0, 0.10000000149011612) ;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.old-home-text41 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190216 {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
 
.old-home-frame48190120 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logogevulot1 {
  width: 56px;
  height: 56px;
}
 
.old-home-text42 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190121 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logogevulot2 {
  width: 56px;
  height: 56px;
}
 
.old-home-text43 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190122 {
  gap: 16px;
  width: auto;
  height: auto;
  display: flex;
  padding: 32px;
  overflow: hidden;
  box-shadow: 0px 16px 16px -18px rgba(0, 0, 0, 0.10000000149011612) ;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.old-home-text44 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190217 {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
 
.old-home-frame48190016 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logoredstone1 {
  width: 56px;
  height: 56px;
}
 
.old-home-text45 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190017 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logoredstone2 {
  width: 56px;
  height: 56px;
}
 
.old-home-text46 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190218 {
  gap: 16px;
  width: auto;
  height: auto;
  display: flex;
  padding: 32px;
  overflow: hidden;
  box-shadow: 0px 16px 16px -18px rgba(0, 0, 0, 0.10000000149011612) ;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.old-home-text47 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190219 {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
 
.old-home-frame48190018 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logoaxelar {
  width: 56px;
  height: 56px;
}
 
.old-home-text48 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190123 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logozkevm6 {
  width: 56px;
  height: 56px;
}
 
.old-home-text49 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame481912 {
  gap: 16px;
  width: auto;
  height: auto;
  display: flex;
  padding: 32px;
  overflow: hidden;
  box-shadow: 0px 16px 16px -18px rgba(0, 0, 0, 0.10000000149011612) ;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.old-home-text50 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190220 {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
 
.old-home-frame48190019 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logosub76 {
  width: 56px;
  height: 56px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.old-home-maskgroup {
  top: 6.125px;
  left: 9.625px;
  width: 36.75px;
  height: 43.75px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.old-home-q-ixc-oq-ye-rmw-c85-rhv-bu4y-yhyou1 {
  top: 0px;
  left: 0px;
  width: 37px;
  height: 44px;
  position: absolute;
}
 
.old-home-text51 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190124 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logoquantstamp {
  width: 56px;
  height: 56px;
}
 
.old-home-text52 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190221 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logooxorio {
  width: 56px;
  height: 56px;
}
 
.old-home-text53 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame4819033 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logoshieldifysecurity {
  width: 56px;
  height: 56px;
}
 
.old-home-text54 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame4819034 {
  gap: 16px;
  width: auto;
  height: auto;
  display: flex;
  padding: 32px;
  overflow: hidden;
  box-shadow: 0px 16px 16px -18px rgba(0, 0, 0, 0.10000000149011612) ;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.old-home-text55 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190222 {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
 
.old-home-frame48190020 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logoaws {
  width: 56px;
  height: 56px;
}
 
.old-home-text56 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190125 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logogooglecloudgcp {
  width: 56px;
  height: 56px;
}
 
.old-home-text57 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190223 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logomicrosoftazure {
  width: 56px;
  height: 56px;
}
 
.old-home-text58 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame4819035 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logobaremetal {
  width: 56px;
  height: 56px;
}
 
.old-home-text59 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: Gotham;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame481899 {
  gap: 16px;
  width: auto;
  height: auto;
  display: flex;
  padding: 32px;
  overflow: hidden;
  box-shadow: 0px 16px 16px -18px rgba(0, 0, 0, 0.10000000149011612) ;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.old-home-text60 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190224 {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
 
.old-home-frame48190126 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logogoldsky1 {
  width: 56px;
  height: 56px;
}
 
.old-home-text61 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190127 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logogoldsky2 {
  width: 56px;
  height: 56px;
}
 
.old-home-text62 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame481907 {
  gap: 16px;
  width: auto;
  height: auto;
  display: flex;
  padding: 32px;
  overflow: hidden;
  box-shadow: 0px 16px 16px -18px rgba(0, 0, 0, 0.10000000149011612) ;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.old-home-text63 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190225 {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
 
.old-home-frame48190021 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logomagiclink {
  width: 56px;
  height: 56px;
}
 
.old-home-text64 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-frame48190226 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.old-home-logometamask {
  width: 56px;
  height: 56px;
}
 
.old-home-text65 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-container29 {
  gap: 24px;
  display: flex;
  padding: 32px 56px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: 0px 8px 24px -20px rgba(127, 127, 127, 0.5) ;
  align-items: flex-start;
  border-radius: 29px;
  background-size: cover;
  background-image: url("/external/node-sale-back-min-1200w.jpg");
}
 
.old-home-content4 {
  gap: 8px;
  width: 1014px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.old-home-title2 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.old-home-text66 {
  color: var(--dl-color-default-secondarytext);
  height: auto;
  font-size: 40px;
  font-style: Black;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 900;
  line-height: 56px;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-text70 {
  color: var(--dl-color-default-secondarytext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 700;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.old-home-navlink25 {
  display: contents;
}
 
.old-home-component42 {
  text-decoration: none;
}
 
.old-home-navlink26 {
  display: contents;
}
 
.old-home-component44 {
  text-decoration: none;
}
 
.old-home-scheme-container {
  width: 100%;
  display: flex;
  overflow: visible;
  overflow-x: scroll;
  align-items: center;
  flex-direction: column;
}
 
.old-home-image2 {
  width: 100%;
  object-fit: cover;
}
 
.old-home-navlink27 {
  display: contents;
}
 
.old-home-component47 {
  text-decoration: none;
}
 
.old-home-testimonials1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
 
.old-home-testimonials2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
 
.old-home-link3 {
  display: contents;
}
 
.old-home-component55 {
  text-decoration: none;
}
 
.old-home-link4 {
  display: contents;
}
 
.old-home-component57 {
  text-decoration: none;
}
 
.old-home-image3 {
  width: 800px;
  object-fit: cover;
}
 
@media(max-width: 1200px) {
  .old-home-container10 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .old-home-image1 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: -48px;
  }
  .old-home-container19 {
    display: flex;
  }
  .old-home-text27 {
    color: rgb(0, 0, 0);
    font-size: 12px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
  }
  .old-home-text36 {
    color: rgb(0, 0, 0);
    font-size: 12px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
  }
  .old-home-frame48190014 {
    width: 307px;
  }
  .old-home-container28 {
    width: 240px;
  }
  .old-home-text40 {
    color: rgb(0, 0, 0);
  }
  .old-home-testimonials1 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    flex-direction: row;
  }
  .old-home-testimonials2 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    flex-direction: row;
  }
}
 
@media(max-width: 767px) {
  .old-home-play1 {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: auto;
    align-items: center;
    justify-content: center;
  }
  .old-home-testimonials1 {
    flex-direction: column;
  }
  .old-home-testimonials2 {
    flex-direction: column;
  }
}
 
@media(max-width: 550px) {
  .old-home-video-container1 {
    gap: var(--dl-space-space-unit);
    height: 614px;
  }
  .old-home-video1 {
    width: auto;
    height: 100%;
  }
  .old-home-hero {
    height: 100%;
    flex-direction: column;
  }
  .old-home-container11 {
    gap: var(--dl-space-space-unit);
  }
  .old-home-description {
    width: 100%;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .old-home-text10 {
    font-size: 44px;
  }
  .old-home-text14 {
    color: rgb(0, 0, 0);
  }
  .old-home-buttons {
    align-items: center;
    flex-direction: column;
  }
  .old-home-image1 {
    margin-top: -24px;
    margin-left: 60%;
    margin-bottom: 0px;
  }
  .old-home-container14 {
    gap: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .old-home-gradient {
    height: 24px;
  }
  .old-home-logo-container {
    padding-top: var(--dl-space-space-unit);
  }
  .old-home-content1 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .old-home-content2 {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
    justify-content: unset;
  }
  .old-home-play1 {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: auto;
  }
  .old-home-container18 {
    gap: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }
  .old-home-container19 {
    display: none;
  }
  .old-home-container20 {
    gap: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }
  .old-home-container21 {
    gap: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }
  .old-home-container22 {
    gap: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }
  .old-home-container23 {
    flex-direction: column;
  }
  .old-home-container24 {
    flex-direction: column;
  }
  .old-home-frame481909 {
    flex-wrap: wrap;
  }
  .old-home-frame48190210 {
    flex-wrap: wrap;
  }
  .old-home-frame48190211 {
    flex-wrap: wrap;
  }
  .old-home-frame48190213 {
    flex-wrap: wrap;
  }
  .old-home-frame481912 {
    flex-wrap: wrap;
  }
  .old-home-frame48190220 {
    flex-wrap: wrap;
  }
  .old-home-frame4819034 {
    flex-wrap: wrap;
  }
  .old-home-frame48190222 {
    flex-wrap: wrap;
  }
  .old-home-container29 {
    gap: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .old-home-content4 {
    gap: var(--dl-space-space-unit);
    width: 100%;
  }
  .old-home-text66 {
    font-size: 28px;
    line-height: 32px;
  }
  .old-home-image2 {
    width: 200%;
    align-self: flex-start;
  }
  .old-home-testimonials1 {
    flex-direction: column;
  }
  .old-home-testimonials2 {
    flex-direction: column;
  }
  .old-home-image3 {
    width: 100%;
  }
}
