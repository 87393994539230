.selector-chain-container {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.selector-chain-unselected {
  gap: 8px;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
 
.selector-chain-unselected:hover {
  background-color: var(--dl-color-default-secondarytext);
}
 
.selector-chain-unselected:active {
  background-color: var(--dl-color-default-secondarytext);
}
 
.selector-chain-gnosisgnognologo {
  width: 24px;
  height: 24px;
}
 
.selector-chain-text {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 20px;
  font-style: Book;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 300;
  line-height: 24px;
  white-space: nowrap;
  font-stretch: normal;
  text-decoration: none;
}
 
@media(max-width: 550px) {
  .selector-chain-text {
    font-family: Gotham-Medium;
  }
}
