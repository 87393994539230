.blog-post-featured-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.blog-post-featured-featuredpost {
  gap: 32px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.blog-post-featured-gatewaywirexcasestudy1 {
  width: 639px;
  height: 360px;
  border-radius: 30px;
}
 
.blog-post-featured-featuredtitle {
  gap: 16px;
  width: 455px;
  display: flex;
  padding: 24px;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.blog-post-featured-featuredtags {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
 
.blog-post-featured-text1 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 32px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 40px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-post-featured-text2 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Book;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 300;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-post-featured-text3 {
  display: inline-block;
}
 

 
@media(max-width: 1200px) {
  .blog-post-featured-text1 {
    color: rgb(0, 0, 0);
  }
  .blog-post-featured-text2 {
    color: var(--dl-color-default-maintext);
  }
  .blog-post-featuredroot-class-name2 {
    top: 224px;
    left: 0px;
    position: static;
  }
}
 
@media(max-width: 550px) {
  .blog-post-featured-featuredpost {
    gap: var(--dl-space-space-halfunit);
    flex-direction: column;
  }
  .blog-post-featured-gatewaywirexcasestudy1 {
    width: 100%;
    height: auto;
  }
  .blog-post-featured-featuredtitle {
    width: 100%;
  }
  .blog-post-featured-text1 {
    font-size: 24px;
    line-height: 32px;
  }
}
