.card-plan-rpc-copy-container1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.card-plan-rpc-copy-container2 {
  gap: 46px;
  width: 100%;
  display: flex;
  padding: 49px 50px;
  overflow: hidden;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 59px;
  flex-direction: column;
  background-color: rgba(40, 40, 40, 1);
}
 
.card-plan-rpc-copy-texts {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.card-plan-rpc-copy-text1 {
  color: rgb(255, 255, 255);
  width: 100%;
  height: auto;
  font-size: 36px;
  font-style: Black;
  text-align: left;
  font-family: "Gotham-Black";
  font-weight: 900;
  line-height: 40px;
  font-stretch: normal;
  text-decoration: none;
}
 
.card-plan-rpc-copy-flags {
  gap: 8px;
  display: flex;
  align-items: flex-start;
}
 
.card-plan-rpc-copy-singapore {
  width: 24px;
  height: 24px;
}
 
.card-plan-rpc-copy-switzerland {
  width: 24px;
  height: 24px;
}
 
.card-plan-rpc-copy-sweden {
  width: 24px;
  height: 24px;
}
 
.card-plan-rpc-copy-germany {
  width: 24px;
  height: 24px;
}
 
.card-plan-rpc-copy-us-ps {
  gap: 8px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.card-plan-rpc-copy-container3 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.card-plan-rpc-copy-content1 {
  gap: 16px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
 
.card-plan-rpc-copy-check1 {
  width: 24px;
  height: 24px;
}
 
.card-plan-rpc-copy-text2 {
  color: #ffffff;
  height: auto;
  font-size: 18px;
  font-style: Book;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 300;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.card-plan-rpc-copy-container4 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.card-plan-rpc-copy-content2 {
  gap: 16px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
 
.card-plan-rpc-copy-check2 {
  width: 24px;
  height: 24px;
}
 
.card-plan-rpc-copy-text3 {
  color: #ffffff;
  height: auto;
  font-size: 18px;
  font-style: Book;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 300;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.card-plan-rpc-copy-container5 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.card-plan-rpc-copy-content3 {
  gap: 16px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
 
.card-plan-rpc-copy-check3 {
  width: 24px;
  height: 24px;
}
 
.card-plan-rpc-copy-text4 {
  color: #ffffff;
  height: auto;
  font-size: 18px;
  font-style: Book;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 300;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.card-plan-rpc-copy-link {
  gap: var(--dl-space-space-unit);
  height: 72px;
  display: flex;
  padding: 8px 16px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 20px;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--dl-color-default-secondarytext);
}
 
.card-plan-rpc-copy-text5 {
  color: var(--dl-color-default-maintext);
  height: 56px;
  display: flex;
  font-size: 16px;
  max-width: 250px;
  align-self: stretch;
  font-style: Bold;
  align-items: center;
  font-family: "Gotham-Medium";
  font-weight: 700;
  line-height: 24px;
  white-space: nowrap;
  font-stretch: normal;
  text-overflow: ellipsis;
  text-decoration: none;
}
 
.card-plan-rpc-copy-container6 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
 
.card-plan-rpc-copy-text6 {
  color: var(--dl-color-default-maininteractive);
  height: auto;
  display: none;
  font-size: 16px;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 24px;
  padding-top: var(--dl-space-space-halfunit);
  font-stretch: normal;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
  background-color: var(--dl-color-default-secondarytext);
}
 
@media(max-width: 550px) {
  .card-plan-rpc-copy-container1 {
    height: auto;
  }
  .card-plan-rpc-copy-container2 {
    height: auto;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    border-radius: 29px;
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .card-plan-rpc-copy-text2 {
    fill: var(--dl-color-default-secondarytext);
    color: var(--dl-color-default-secondarytext);
  }
  .card-plan-rpc-copy-text3 {
    fill: var(--dl-color-default-secondarytext);
    color: var(--dl-color-default-secondarytext);
  }
  .card-plan-rpc-copy-text4 {
    fill: var(--dl-color-default-secondarytext);
    color: var(--dl-color-default-secondarytext);
  }
  .card-plan-rpc-copy-text5 {
    max-width: 200px;
  }
}
