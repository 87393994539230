.from-rpc-to-real-world-container1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/neon-back-1500w.png");
}
 
.from-rpc-to-real-world-container2 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 1126px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  flex-direction: column;
}
 
.from-rpc-to-real-world-post {
  gap: 32px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.from-rpc-to-real-world-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}
 
.from-rpc-to-real-world-text10 {
  gap: 32px;
  width: 100%;
  display: flex;
  max-width: 800px;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.from-rpc-to-real-world-container3 {
  gap: 16px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.from-rpc-to-real-world-tags {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
 
.from-rpc-to-real-world-text11 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 32px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 40px;
  font-stretch: normal;
  text-decoration: none;
}
 
.from-rpc-to-real-world-text12 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Book;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 300;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.from-rpc-to-real-world-text13 {
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
}
 
.from-rpc-to-real-world-container4 {
  width: 100%;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
 
.from-rpc-to-real-world-container5 {
  display: contents;
}
 
.from-rpc-to-real-world-text16 {
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
}
 
@media(max-width: 1200px) {
  .from-rpc-to-real-world-image {
    box-shadow: 5px 5px 10px 0px #d4d4d4;
  }
  .from-rpc-to-real-world-text11 {
    color: rgb(0, 0, 0);
    font-size: 32px;
    font-family: Gotham-Bold;
    font-weight: 700;
    line-height: 40px;
    text-decoration: none;
  }
  .from-rpc-to-real-world-text12 {
    color: var(--dl-color-default-maintext);
  }
  .from-rpc-to-real-world-text13 {
    color: var(--dl-color-default-maintext);
    font-family: Gotham-Medium;
  }
  .from-rpc-to-real-world-text15 {
    color: var(--dl-color-default-maintext);
    text-decoration: none;
  }
  .from-rpc-to-real-world-text16 {
    color: var(--dl-color-default-maintext);
  }
  .from-rpc-to-real-world-text78 {
    color: var(--dl-color-default-maintext);
    text-decoration: none;
  }
}
 
@media(max-width: 991px) {
  .from-rpc-to-real-world-container2 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
}
 
@media(max-width: 767px) {
  .from-rpc-to-real-world-container2 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
}
 
@media(max-width: 550px) {
  .from-rpc-to-real-world-container2 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .from-rpc-to-real-world-post {
    width: 100%;
  }
}
