.card-plan-rpc-container1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.card-plan-rpc-container2 {
  gap: 46px;
  width: 100%;
  display: flex;
  padding: 49px 50px;
  overflow: hidden;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 59px;
  flex-direction: column;
  background-color: rgba(40, 40, 40, 1);
}
 
.card-plan-rpc-texts {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.card-plan-rpc-text1 {
  color: rgb(255, 255, 255);
  width: 100%;
  height: auto;
  font-size: 36px;
  font-style: Black;
  text-align: left;
  font-family: "Gotham-Black";
  font-weight: 900;
  line-height: 40px;
  font-stretch: normal;
  text-decoration: none;
}
 
.card-plan-rpc-flags {
  gap: 8px;
  display: flex;
  align-items: flex-start;
}
 
.card-plan-rpc-singapore {
  width: 24px;
  height: 24px;
}
 
.card-plan-rpc-switzerland {
  width: 24px;
  height: 24px;
}
 
.card-plan-rpc-sweden {
  width: 24px;
  height: 24px;
}
 
.card-plan-rpc-germany {
  width: 24px;
  height: 24px;
}
 
.card-plan-rpcus-ps {
  gap: 8px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.card-plan-rpc-container3 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.card-plan-rpc-content1 {
  gap: 16px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
 
.card-plan-rpc-check1 {
  width: 24px;
  height: 24px;
}
 
.card-plan-rpc-text2 {
  color: #ffffff;
  height: auto;
  font-size: 18px;
  font-style: Book;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 300;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.card-plan-rpc-container4 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.card-plan-rpc-content2 {
  gap: 16px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
 
.card-plan-rpc-check2 {
  width: 24px;
  height: 24px;
}
 
.card-plan-rpc-text3 {
  color: #ffffff;
  height: auto;
  font-size: 18px;
  font-style: Book;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 300;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.card-plan-rpc-container5 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.card-plan-rpc-content3 {
  gap: 16px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
 
.card-plan-rpc-check3 {
  width: 24px;
  height: 24px;
}
 
.card-plan-rpc-text4 {
  color: #ffffff;
  height: auto;
  font-size: 18px;
  font-style: Book;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 300;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.card-plan-rpc-link {
  display: contents;
}
 
.card-plan-rpc-component {
  text-decoration: none;
}
 

 

 

 

 

 

 
@media(max-width: 550px) {
  .card-plan-rpc-container1 {
    height: auto;
  }
  .card-plan-rpc-container2 {
    height: auto;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    border-radius: 29px;
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .card-plan-rpc-text2 {
    fill: var(--dl-color-default-secondarytext);
    color: var(--dl-color-default-secondarytext);
  }
  .card-plan-rpc-text3 {
    fill: var(--dl-color-default-secondarytext);
    color: var(--dl-color-default-secondarytext);
  }
  .card-plan-rpc-text4 {
    fill: var(--dl-color-default-secondarytext);
    color: var(--dl-color-default-secondarytext);
  }
  .card-plan-rp-croot-class-name {
    height: auto;
  }
  .card-plan-rp-croot-class-name1 {
    height: auto;
  }
  .card-plan-rp-croot-class-name2 {
    height: auto;
  }
  .card-plan-rp-croot-class-name3 {
    height: auto;
  }
  .card-plan-rp-croot-class-name4 {
    height: auto;
  }
  .card-plan-rp-croot-class-name5 {
    height: auto;
  }
}
