.card-number-container1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.card-number-container2 {
  height: 320px;
  display: flex;
  padding: 24px 32px;
  overflow: hidden;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  border-radius: 30px;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
}
 
.card-number-text1 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 60px;
  align-self: stretch;
  font-style: Black;
  text-align: left;
  font-family: "Gotham-Black";
  font-weight: 900;
  line-height: 72px;
  font-stretch: normal;
  text-decoration: none;
}
 
.card-number-text2 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 300px;
  line-height: 28px;
  font-stretch: normal;
  letter-spacing: 0.5px;
}
 
@media(max-width: 550px) {
  .card-number-container2 {
    height: auto;
    min-height: 250px;
  }
}
