.card-case-study-container1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.card-case-study-container2 {
  gap: 24px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 16px 24px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: 0px 8px 24px -20px rgba(127, 127, 127, 0.5) ;
  transition: 0.3s;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.30000001192092896);
}
 
.card-case-study-container2:hover {
  box-shadow: 0px 8px 24px -20px rgba(137, 80, 250, 0.8);
}
 
.card-case-study-content {
  gap: 12px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: center;
}
 
.card-case-study-gnosisgnognologo {
  width: 32px;
  height: 32px;
}
 
.card-case-study-container3 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.card-case-study-text1 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.card-case-study-text2 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Book;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 300;
  line-height: 18px;
  font-stretch: normal;
  text-decoration: none;
}
 
@media(max-width: 1200px) {
  .card-case-study-text2 {
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-family: Gotham-Book;
    font-weight: 300;
    line-height: 18px;
    text-decoration: none;
  }
}
 
@media(max-width: 550px) {
  .card-case-study-container3 {
    flex-direction: column;
  }
  .card-case-study-text1 {
    width: 100%;
  }
}
