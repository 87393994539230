.about-container1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/neon-back-1500w.png");
}
 
.about-hero {
  gap: 24px;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-sixunits);
  flex-direction: column;
}
 
.about-frame1171275211 {
  gap: 24px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.about-text10 {
  color: rgba(0, 0, 0, 1);
  width: 1126px;
  height: auto;
  font-size: 72px;
  font-style: Black;
  text-align: center;
  font-family: "Gotham-Ultra";
  font-weight: 900;
  line-height: 72px;
  font-stretch: normal;
  text-decoration: none;
}
 
.about-cubebitmin1 {
  width: 862px;
  height: 249px;
  z-index: 1;
}
 
.about-frame482057 {
  gap: 120px;
  width: 1126px;
  height: 166px;
  display: flex;
  padding: 48px;
  z-index: 2;
  overflow: hidden;
  box-shadow: 0px 4px 32px -12px rgba(0, 0, 0, 0.25) ;
  margin-top: -96px;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 2px 0 0 2px;
  border-radius: 29px;
  flex-direction: column;
  backdrop-filter: blur(64px);
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
}
 
.about-text11 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 32px;
  font-stretch: normal;
  text-decoration: none;
}
 
.about-legs {
  gap: 24px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.about-row11 {
  gap: 16px;
  width: 1126px;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: row;
}
 
.about-presto1 {
  gap: 24px;
  height: 473px;
  display: flex;
  padding: 48px;
  overflow: hidden;
  flex-grow: 1;
  align-self: stretch;
  box-shadow: 0px 4px 32px -12px rgba(0, 0, 0, 0.25) ;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 2px 0 0 2px;
  border-radius: 29px;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(137, 80, 250, 1);
}
 
.about-frame1171275204 {
  gap: 89px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: space-between;
}
 
.about-group481543 {
  width: 160px;
  height: 38.59576416015625px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.about-presto2 {
  top: 3.56325364112854px;
  left: 52.37493133544922px;
  width: 107.62506866455078px;
  height: 18.864702224731445px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.about-vector10 {
  top: -0.000222873073653318px;
  left: 88.27826690673828px;
  width: 19px;
  height: 19px;
  position: absolute;
  box-sizing: content-box;
}
 
.about-vector11 {
  top: 0.3122771084308624px;
  left: 72.84498596191406px;
  width: 16px;
  height: 18px;
  position: absolute;
  box-sizing: content-box;
}
 
.about-vector12 {
  top: 0.05186045914888382px;
  left: 53.47906494140625px;
  width: 17px;
  height: 19px;
  position: absolute;
  box-sizing: content-box;
}
 
.about-vector13 {
  top: 0.3122771084308624px;
  left: 36.68989944458008px;
  width: 19px;
  height: 18px;
  position: absolute;
  box-sizing: content-box;
}
 
.about-vector14 {
  top: 0.3122771084308624px;
  left: 17.720256805419922px;
  width: 19px;
  height: 18px;
  position: absolute;
  box-sizing: content-box;
}
 
.about-vector15 {
  top: 0.3122771084308624px;
  left: -0.00006853070226497948px;
  width: 18px;
  height: 18px;
  position: absolute;
  box-sizing: content-box;
}
 
.about-poweredby-gatewayfm1 {
  top: 27.001094818115234px;
  left: 93.31431579589844px;
  width: 65.0886001586914px;
  height: 7.914574146270752px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.about-vector16 {
  top: 1.6798062324523926px;
  left: 57.0611686706543px;
  width: 8px;
  height: 5px;
  position: absolute;
  box-sizing: content-box;
}
 
.about-vector17 {
  top: 0.00011880505917361006px;
  left: 53.676265716552734px;
  width: 4px;
  height: 6px;
  position: absolute;
  box-sizing: content-box;
}
 
.about-vector18 {
  top: 5.074131965637207px;
  left: 50.993289947509766px;
  width: 2px;
  height: 1px;
  position: absolute;
  box-sizing: content-box;
}
 
.about-vector19 {
  top: 1.767868161201477px;
  left: 46.262786865234375px;
  width: 6px;
  height: 6px;
  position: absolute;
  box-sizing: content-box;
}
 
.about-vector20 {
  top: 1.715099573135376px;
  left: 41.76340103149414px;
  width: 5px;
  height: 5px;
  position: absolute;
  box-sizing: content-box;
}
 
.about-vector21 {
  top: 1.7500501871109009px;
  left: 35.059730529785156px;
  width: 7px;
  height: 5px;
  position: absolute;
  box-sizing: content-box;
}
 
.about-vector22 {
  top: 1.6798062324523926px;
  left: 29.507884979248047px;
  width: 5px;
  height: 5px;
  position: absolute;
  box-sizing: content-box;
}
 
.about-vector23 {
  top: 0.562755823135376px;
  left: 26.060277938842773px;
  width: 3px;
  height: 6px;
  position: absolute;
  box-sizing: content-box;
}
 
.about-vector24 {
  top: 1.715099573135376px;
  left: 20.542869567871094px;
  width: 5px;
  height: 5px;
  position: absolute;
  box-sizing: content-box;
}
 
.about-vector25 {
  top: 0.22010235488414764px;
  left: 14.101326942443848px;
  width: 6px;
  height: 6px;
  position: absolute;
  box-sizing: content-box;
}
 
.about-vector26 {
  top: 1.767868161201477px;
  left: 5.310551166534424px;
  width: 6px;
  height: 6px;
  position: absolute;
  box-sizing: content-box;
}
 
.about-vector27 {
  top: 0.061796434223651886px;
  left: 0.00010707922047004104px;
  width: 6px;
  height: 7px;
  position: absolute;
  box-sizing: content-box;
}
 
.about-group11 {
  top: 0px;
  left: 0px;
  width: 43.66701889038086px;
  height: 38.59576416015625px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.about-rectangle32 {
  top: 0px;
  left: 0px;
  width: 20px;
  height: 39px;
  position: absolute;
}
 
.about-rectangle31 {
  top: 3.094161033630371px;
  left: 27.479612350463867px;
  width: 16px;
  height: 26px;
  position: absolute;
}
 
.about-rectangle30 {
  top: 7.393434524536133px;
  left: 16.18746566772461px;
  width: 14px;
  height: 17px;
  position: absolute;
}
 
.about-frame11712752031 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.about-text12 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.about-text13 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 40px;
  align-self: stretch;
  font-style: Ultra;
  text-align: left;
  font-family: "Gotham-Ultra";
  font-weight: 400;
  line-height: 56px;
  font-stretch: normal;
  text-decoration: none;
}
 
.about-stakeway1 {
  gap: 24px;
  height: 473px;
  display: flex;
  padding: 48px;
  overflow: hidden;
  flex-grow: 1;
  align-self: stretch;
  box-shadow: 0px 4px 32px -12px rgba(0, 0, 0, 0.25) ;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(51, 51, 51, 1);
}
 
.about-frame11712752051 {
  gap: 91px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: space-between;
}
 
.about-group481545 {
  width: 160px;
  height: 34.43839645385742px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.about-vector179 {
  top: 0px;
  left: 0px;
  width: 27px;
  height: 13px;
  position: absolute;
}
 
.about-vector28 {
  top: 6.473304271697998px;
  left: 0px;
  width: 27px;
  height: 28px;
  position: absolute;
}
 
.about-stakeway2 {
  top: 7.239875793457031px;
  left: 37.94970703125px;
  width: 122px;
  height: 15px;
  position: absolute;
  box-sizing: content-box;
}
 
.about-poweredby-gatewayfm2 {
  top: 26.15247917175293px;
  left: 103.57413482666016px;
  width: 56.3099250793457px;
  height: 6.850231647491455px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.about-vector29 {
  top: 1.4538570642471313px;
  left: 49.364986419677734px;
  width: 7px;
  height: 4px;
  position: absolute;
  box-sizing: content-box;
}
 
.about-vector30 {
  top: 0.00013460166519507766px;
  left: 46.43685531616211px;
  width: 4px;
  height: 6px;
  position: absolute;
  box-sizing: content-box;
}
 
.about-vector31 {
  top: 4.391775608062744px;
  left: 44.11551284790039px;
  width: 2px;
  height: 1px;
  position: absolute;
  box-sizing: content-box;
}
 
.about-vector32 {
  top: 1.5299615859985352px;
  left: 40.023155212402344px;
  width: 5px;
  height: 5px;
  position: absolute;
  box-sizing: content-box;
}
 
.about-vector33 {
  top: 1.484330415725708px;
  left: 36.13045883178711px;
  width: 4px;
  height: 4px;
  position: absolute;
  box-sizing: content-box;
}
 
.about-vector34 {
  top: 1.5148038864135742px;
  left: 30.330726623535156px;
  width: 6px;
  height: 4px;
  position: absolute;
  box-sizing: content-box;
}
 
.about-vector35 {
  top: 1.4538570642471313px;
  left: 25.5283260345459px;
  width: 4px;
  height: 4px;
  position: absolute;
  box-sizing: content-box;
}
 
.about-vector36 {
  top: 0.48723503947257996px;
  left: 22.545486450195312px;
  width: 3px;
  height: 5px;
  position: absolute;
  box-sizing: content-box;
}
 
.about-vector37 {
  top: 1.484330415725708px;
  left: 17.772375106811523px;
  width: 4px;
  height: 4px;
  position: absolute;
  box-sizing: content-box;
}
 
.about-vector38 {
  top: 0.19039587676525116px;
  left: 12.199219703674316px;
  width: 6px;
  height: 6px;
  position: absolute;
  box-sizing: content-box;
}
 
.about-vector39 {
  top: 1.5299615859985352px;
  left: 4.593979358673096px;
  width: 5px;
  height: 5px;
  position: absolute;
  box-sizing: content-box;
}
 
.about-vector40 {
  top: 0.05334460362792015px;
  left: -0.00008083906141109765px;
  width: 5px;
  height: 6px;
  position: absolute;
  box-sizing: content-box;
}
 
.about-frame11712752032 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.about-text14 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.about-text15 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 40px;
  align-self: stretch;
  font-style: Ultra;
  text-align: left;
  font-family: "Gotham-Ultra";
  font-weight: 400;
  line-height: 56px;
  font-stretch: normal;
  text-decoration: none;
}
 
.about-row21 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
 
.about-gateway {
  gap: 24px;
  width: 550px;
  height: 473px;
  display: flex;
  padding: 48px;
  overflow: hidden;
  box-shadow: 0px 4px 32px -12px rgba(0, 0, 0, 0.25) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
}
 
.about-frame11712752052 {
  gap: 91px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: space-between;
}
 
.about-gatewaylogo {
  width: 160px;
  height: 31px;
}
 
.about-frame11712752033 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.about-text16 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.about-text17 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 40px;
  align-self: stretch;
  font-style: Ultra;
  text-align: left;
  font-family: "Gotham-Ultra";
  font-weight: 400;
  line-height: 56px;
  font-stretch: normal;
  text-decoration: none;
}
 
.about-description {
  gap: 24px;
  width: 550px;
  height: 473px;
  display: flex;
  padding: 48px;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-end;
}
 
.about-frame11712752034 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.about-text18 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 32px;
  align-self: stretch;
  font-style: Bold;
  text-align: right;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 40px;
  font-stretch: normal;
  text-decoration: none;
}
 
.about-execs {
  gap: 24px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.about-container2 {
  gap: 48px;
  width: 1125px;
  display: flex;
  padding: 0 32px;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.about-cco {
  gap: 16px;
  width: 321.66668701171875px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.about-image1 {
  width: 100%;
  object-fit: cover;
}
 
.about-name1 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-end;
  flex-shrink: 0;
}
 
.about-text19 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  flex-grow: 1;
  font-size: 32px;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 48px;
  font-stretch: normal;
  text-decoration: none;
}
 
.about-links1 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
 
.about-link1 {
  display: contents;
}
 
.about-twitter-x1 {
  width: 36px;
  height: 36px;
  text-decoration: none;
}
 
.about-link2 {
  display: contents;
}
 
.about-linked-in1 {
  width: 36px;
  height: 36px;
  text-decoration: none;
}
 
.about-text20 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 18px;
  font-stretch: normal;
  text-decoration: none;
}
 
.about-text21 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 300px;
  line-height: 28px;
  font-stretch: normal;
  letter-spacing: 0.5px;
}
 
.about-cto {
  gap: 16px;
  width: 321.66668701171875px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.about-image2 {
  width: 100%;
  object-fit: cover;
}
 
.about-name2 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-end;
  flex-shrink: 0;
}
 
.about-text22 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  flex-grow: 1;
  font-size: 32px;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 48px;
  font-stretch: normal;
  text-decoration: none;
}
 
.about-links2 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
 
.about-link3 {
  display: contents;
}
 
.about-twitter-x2 {
  width: 36px;
  height: 36px;
  text-decoration: none;
}
 
.about-link4 {
  display: contents;
}
 
.about-linked-in2 {
  width: 36px;
  height: 36px;
  text-decoration: none;
}
 
.about-text23 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 18px;
  font-stretch: normal;
  text-decoration: none;
}
 
.about-text24 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 300px;
  line-height: 28px;
  font-stretch: normal;
  letter-spacing: 0.5px;
}
 
.about-values {
  gap: 24px;
  height: auto;
  display: flex;
  max-width: 1126px;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
 
.about-row12 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: center;
}
 
.about-value1 {
  gap: 24px;
  width: 551px;
  display: flex;
  padding: 48px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: 0px 4px 32px -12px rgba(0, 0, 0, 0.25) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 2px 0 0 2px;
  border-radius: 29px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
}
 
.about-text25 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 48px;
  font-style: Ultra;
  text-align: center;
  font-family: "Gotham-Ultra";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.about-text26 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 700px;
  line-height: 24px;
  font-stretch: normal;
  letter-spacing: 0.15000000596046448px;
}
 
.about-value2 {
  gap: 24px;
  width: 551px;
  display: flex;
  padding: 48px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: 0px 4px 32px -12px rgba(0, 0, 0, 0.25) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 2px 0 0 2px;
  border-radius: 29px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
}
 
.about-text27 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 48px;
  font-style: Ultra;
  text-align: center;
  font-family: "Gotham-Ultra";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.about-text28 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 700px;
  line-height: 24px;
  font-stretch: normal;
  letter-spacing: 0.15000000596046448px;
}
 
.about-row22 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: center;
}
 
.about-value3 {
  gap: 24px;
  width: 551px;
  display: flex;
  padding: 48px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: 0px 4px 32px -12px rgba(0, 0, 0, 0.25) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 2px 0 0 2px;
  border-radius: 29px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
}
 
.about-text29 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 48px;
  font-style: Ultra;
  text-align: center;
  font-family: "Gotham-Ultra";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.about-text30 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 700px;
  line-height: 24px;
  font-stretch: normal;
  letter-spacing: 0.15000000596046448px;
}
 
.about-value4 {
  gap: 24px;
  width: 551px;
  display: flex;
  padding: 48px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: 0px 4px 32px -12px rgba(0, 0, 0, 0.25) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 2px 0 0 2px;
  border-radius: 29px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
}
 
.about-text31 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 48px;
  font-style: Ultra;
  text-align: center;
  font-family: "Gotham-Ultra";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.about-text32 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 700px;
  line-height: 24px;
  font-stretch: normal;
  letter-spacing: 0.15000000596046448px;
}
 
@media(max-width: 1200px) {
  .about-container1 {
    padding-bottom: 0px;
  }
  .about-hero {
    height: auto;
    position: relative;
    padding-top: var(--dl-space-space-fiveunits);
  }
  .about-text10 {
    color: rgb(0, 0, 0);
  }
}
 
@media(max-width: 550px) {
  .about-container1 {
    gap: var(--dl-space-space-unit);
    padding-bottom: 0px;
  }
  .about-hero {
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .about-text10 {
    color: rgb(0, 0, 0);
    width: 100%;
    font-size: 48px;
    font-family: Gotham-Ultra;
    font-weight: 900;
    line-height: 48px;
    text-decoration: none;
  }
  .about-cubebitmin1 {
    width: 100%;
    height: 185px;
  }
  .about-frame482057 {
    width: 100%;
    height: auto;
    padding: var(--dl-space-space-oneandhalfunits);
  }
  .about-text11 {
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-decoration: none;
  }
  .about-legs {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .about-row11 {
    width: 100%;
    flex-direction: column;
  }
  .about-presto1 {
    height: 400px;
    padding: var(--dl-space-space-oneandhalfunits);
    animation-name: none;
    animation-delay: 0s;
    animation-duration: 300ms;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-timing-function: ease;
  }
  .about-stakeway1 {
    height: 400px;
    padding: var(--dl-space-space-oneandhalfunits);
    animation-name: none;
    animation-delay: 0s;
    animation-duration: 300ms;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-timing-function: ease;
  }
  .about-row21 {
    width: 100%;
    flex-direction: column;
  }
  .about-gateway {
    width: 100%;
    height: 400px;
    padding: var(--dl-space-space-oneandhalfunits);
    animation-name: none;
    animation-delay: 0s;
    animation-duration: 300ms;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-timing-function: ease;
  }
  .about-description {
    width: 100%;
    height: 400px;
    padding: var(--dl-space-space-oneandhalfunits);
  }
  .about-text18 {
    font-size: 24px;
    font-family: Gotham-Bold;
    line-height: 32px;
  }
  .about-execs {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .about-container2 {
    width: 100%;
    flex-direction: column;
  }
  .about-cco {
    width: 100%;
  }
  .about-name1 {
    align-items: flex-start;
    flex-direction: column;
  }
  .about-text19 {
    color: rgb(0, 0, 0);
    font-size: 32px;
    font-family: Gotham-Bold;
    font-weight: 700;
    line-height: 48px;
    text-decoration: none;
  }
  .about-text20 {
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-family: Gotham-Bold;
    font-weight: 700;
    line-height: 18px;
    text-decoration: none;
  }
  .about-text21 {
    font-family: Gotham-Book;
  }
  .about-cto {
    width: 100%;
  }
  .about-name2 {
    align-items: flex-start;
    flex-direction: column;
  }
  .about-text22 {
    color: rgb(0, 0, 0);
    font-size: 32px;
    font-family: Gotham-Bold;
    font-weight: 700;
    line-height: 48px;
    text-decoration: none;
  }
  .about-text23 {
    color: rgb(0, 0, 0);
  }
  .about-text24 {
    color: rgb(0, 0, 0);
    font-size: 18px;
    font-style: normal;
    font-family: Gotham-Book;
    line-height: 28px;
    letter-spacing: 0.5px;
  }
  .about-values {
    width: 100%;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .about-row12 {
    width: 100%;
    flex-direction: column;
  }
  .about-value1 {
    width: 100%;
    padding: var(--dl-space-space-oneandhalfunits);
  }
  .about-text25 {
    font-size: 40px;
  }
  .about-value2 {
    width: 100%;
    padding: var(--dl-space-space-oneandhalfunits);
  }
  .about-text27 {
    font-size: 40px;
  }
  .about-row22 {
    width: 100%;
    flex-direction: column;
  }
  .about-value3 {
    width: 100%;
    padding: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .about-text29 {
    font-size: 40px;
  }
  .about-value4 {
    width: 100%;
    padding: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .about-text31 {
    font-size: 40px;
  }
}
