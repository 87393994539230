.card-rule-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.card-rule-rule {
  gap: 24px;
  width: 100%;
  display: flex;
  padding: 32px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: 0px 4px 32px -12px rgba(0, 0, 0, 0.25) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 2px 0 0 2px;
  border-radius: 29px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
}
 
.card-rule-title {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
 
.card-rule-text1 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 24px;
  font-style: normal;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700px;
  line-height: 28px;
  font-stretch: normal;
  letter-spacing: 0em;
}
 
.card-rule-check {
  width: 24px;
  height: 24px;
}
 
.card-rule-text2 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 300px;
  line-height: 28px;
  font-stretch: normal;
  letter-spacing: 0.5px;
}
