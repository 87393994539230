.card-blueprint-container1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
}
 
.card-blueprint-container1:hover {
  box-shadow: 0px 12px 20px -12px rgba(137, 80, 250, 0.59);
}
 
.card-blueprint-container2 {
  gap: 0;
  width: 100%;
  height: auto;
  display: flex;
  padding: 24px;
  flex-grow: 1;
  box-shadow: 0px 4px 16px -20px rgba(137, 80, 250, 0.20000000298023224) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 20px;
  flex-direction: column;
  background-color: rgba(246, 246, 251, 1);
}
 
.card-blueprint-coin21 {
  width: 200px;
  height: 200px;
  align-self: center;
  margin-top: -90px;
}
 
.card-blueprint-content {
  gap: 16px;
  display: flex;
  z-index: 1;
  align-self: stretch;
  align-items: flex-start;
  padding-left: 80px 0 0;
  padding-right: 80px 0 0;
  flex-direction: column;
  padding-bottom: 80px 0 0;
}
 
.card-blueprint-interaction {
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
 
.card-blueprint-title {
  width: 90%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.card-blueprint-text1 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700px;
  line-height: 28px;
  font-stretch: normal;
  letter-spacing: 0em;
}
 
.card-blueprint-text2 {
  color: rgba(0, 0, 0, 1);
  width: auto;
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Book;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 300;
  line-height: 18px;
  font-stretch: normal;
  text-decoration: none;
}
 
.card-blueprint-arrowright {
  width: 32px;
  height: 32px;
}
 
.card-blueprint-usps {
  gap: 24px;
  width: 100%;
  display: flex;
  align-items: center;
}
 
.card-blueprint-container3 {
  gap: 4px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.card-blueprint-usp1 {
  gap: 16px;
  display: flex;
  align-items: center;
}
 
.card-blueprint-check1 {
  width: 24px;
  height: 24px;
}
 
.card-blueprint-text3 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  font-style: Book;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 300;
  line-height: 18px;
  font-stretch: normal;
  text-decoration: none;
}
 
.card-blueprint-usp2 {
  gap: 16px;
  display: flex;
  align-items: center;
}
 
.card-blueprint-check2 {
  width: 24px;
  height: 24px;
}
 
.card-blueprint-text4 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  font-style: Book;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 300;
  line-height: 18px;
  font-stretch: normal;
  text-decoration: none;
}
 
.card-blueprint-usp3 {
  gap: 16px;
  display: flex;
  align-items: center;
}
 
.card-blueprint-check3 {
  width: 24px;
  height: 24px;
}
 
.card-blueprint-text5 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  font-style: Book;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 300;
  line-height: 18px;
  font-stretch: normal;
  text-decoration: none;
}
 
.card-blueprintroot-class-name {
  max-width: 360px;
}
 
.card-blueprintroot-class-name1 {
  max-width: 360px;
}
 
.card-blueprintroot-class-name2 {
  max-width: 360px;
}
 
.card-blueprintroot-class-name3 {
  max-width: 360px;
}
 
.card-blueprintroot-class-name4 {
  max-width: 360px;
}
 
.card-blueprintroot-class-name5 {
  max-width: 360px;
}
 
.card-blueprintroot-class-name6 {
  max-width: 360px;
}
 
.card-blueprintroot-class-name7 {
  max-width: 360px;
}
 
.card-blueprintroot-class-name8 {
  max-width: 360px;
}
 
.card-blueprintroot-class-name9 {
  max-width: 360px;
}
 
.card-blueprintroot-class-name10 {
  max-width: 360px;
}
 
.card-blueprintroot-class-name11 {
  max-width: 360px;
}
