.contact-form-container1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/neon-back-1500w.png");
}
 
.contact-form-content {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 1126px;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
 
.contact-form-hero {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
 
.contact-form-description {
  gap: var(--dl-space-space-twounits);
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.contact-form-container2 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.contact-form-text1 {
  color: rgb(0, 0, 0);
  width: 100%;
  height: auto;
  font-size: 40px;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Black";
  font-weight: 700px;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.contact-form-container3 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.contact-form-form {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 50%;
  height: auto;
  display: flex;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 29px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  background-color: var(--dl-color-default-secondarytext);
}
 
.contact-form-component19 {
  text-decoration: none;
}
 
.contact-form-step2 {
  gap: var(--dl-space-space-unit);
  display: none;
  flex-direction: column;
}
 
.contact-form-container4 {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-direction: row;
}
 
.contact-form-text2 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: left;
  font-family: Gotham;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
 
.contact-form-component26 {
  text-decoration: none;
}
 
.contact-form-step3 {
  gap: var(--dl-space-space-unit);
  display: none;
  flex-direction: column;
}
 
.contact-form-container5 {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-direction: row;
}
 
.contact-form-text3 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: left;
  font-family: Gotham;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
 
.contact-form-component34 {
  text-decoration: none;
}
 
.contact-form-step4 {
  display: none;
}
 
.contact-form-container6 {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-direction: row;
}
 
.contact-form-text4 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: left;
  font-family: Gotham;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
 
.contact-form-component42 {
  text-decoration: none;
}
 
.contact-form-step5 {
  gap: var(--dl-space-space-unit);
  display: none;
  flex-direction: column;
}
 
.contact-form-lottie-node {
  width: 300px;
  height: 300px;
  align-self: center;
}
 
@media(max-width: 1200px) {
  .contact-form-container1 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .contact-form-step2 {
    display: none;
  }
  .contact-form-step3 {
    display: none;
  }
  .contact-form-step4 {
    display: none;
  }
  .contact-form-step5 {
    display: none;
  }
}
 
@media(max-width: 550px) {
  .contact-form-content {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .contact-form-hero {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: unset;
  }
  .contact-form-description {
    width: 100%;
  }
  .contact-form-text1 {
    font-size: 44px;
  }
  .contact-form-form {
    width: 100%;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .contact-form-step2 {
    display: none;
  }
  .contact-form-text2 {
    font-family: Gotham-Bold;
  }
  .contact-form-step3 {
    display: none;
  }
  .contact-form-text3 {
    font-family: Gotham-Bold;
  }
  .contact-form-step4 {
    display: none;
  }
  .contact-form-text4 {
    font-family: Gotham-Bold;
  }
}
