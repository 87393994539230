.subtitle-product-hero-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.subtitle-product-hero-text {
  color: rgba(0, 0, 0, 1);
  width: 100%;
  height: auto;
  font-size: 24px;
  font-style: Book;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 300;
  line-height: 32px;
  font-stretch: normal;
  text-decoration: none;
}
