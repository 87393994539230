.presto-container10 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/neon-back-1500w.png");
}
 
.presto-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 1126px;
  align-items: center;
  flex-direction: column;
}
 
.presto-hero {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
  background-size: contain;
  justify-content: space-between;
  background-image: url("/external/presto-visual-1200w.png");
  background-repeat: no-repeat;
  background-position: right;
}
 
.presto-description {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 45%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.presto-title {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.presto-us-ps {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.presto-buttons {
  gap: 16px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.presto-link1 {
  display: contents;
}
 
.presto-component16 {
  text-decoration: none;
}
 
.presto-link2 {
  display: contents;
}
 
.presto-component17 {
  text-decoration: none;
}
 
.presto-container11 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.presto-container12 {
  gap: 24px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  backdrop-filter: blur(64px);
  justify-content: flex-end;
}
 
.presto-content2 {
  gap: 24px;
  display: flex;
  padding: 32px 56px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: 0px 8px 24px -20px rgba(127, 127, 127, 0.5) ;
  align-items: center;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.30000001192092896);
}
 
.presto-video1 {
  gap: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
 
.presto-play1 {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 82px;
  bottom: 0px;
  height: 82px;
  margin: auto;
  display: flex;
  z-index: 1;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 120px;
  background-color: rgba(255, 255, 255, 0.5);
}
 
.presto-play2 {
  top: 15px;
  left: 21px;
  width: 40px;
  height: 40px;
  position: absolute;
}
 
.presto-text10 {
  top: 53px;
  left: 28px;
  color: rgba(0, 0, 0, 1);
  height: auto;
  position: absolute;
  font-size: 12px;
  font-style: Book;
  text-align: center;
  font-family: Gotham;
  font-weight: 300;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-video-container {
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-video2 {
  width: 100%;
  height: auto;
  max-width: 800px;
  border-radius: 29px;
}
 
.presto-content3 {
  gap: 24px;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  flex-wrap: wrap;
  box-shadow: 0px 8px 24px -20px rgba(127, 127, 127, 0.5) ;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 29px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: rgba(255, 255, 255, 0.30000001192092896);
}
 
.presto-frame481909 {
  gap: 16px;
  width: auto;
  height: auto;
  display: flex;
  padding: 32px;
  overflow: hidden;
  box-shadow: 0px 16px 16px -18px rgba(0, 0, 0, 0.10000000149011612) ;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.presto-text12 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190210 {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
 
.presto-frame48190010 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logopolygon {
  width: 56px;
  height: 56px;
}
 
.presto-text13 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190110 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logooptimism1 {
  width: 56px;
  height: 56px;
}
 
.presto-text14 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190111 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logooptimism2 {
  width: 56px;
  height: 56px;
}
 
.presto-text15 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190112 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logooptimism3 {
  width: 56px;
  height: 56px;
}
 
.presto-text16 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190113 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logooptimism4 {
  width: 56px;
  height: 56px;
}
 
.presto-text17 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame481906 {
  gap: 16px;
  width: auto;
  height: auto;
  display: flex;
  padding: 32px;
  overflow: hidden;
  box-shadow: 0px 16px 16px -18px rgba(0, 0, 0, 0.10000000149011612) ;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.presto-text18 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190211 {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
 
.presto-frame48190011 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logonear {
  width: 56px;
  height: 56px;
}
 
.presto-text19 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190114 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logocelestia {
  width: 56px;
  height: 56px;
}
 
.presto-text20 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame4819031 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logodomicon {
  width: 56px;
  height: 56px;
  display: flex;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 16px 16px -12px rgba(0, 0, 0, 0.10000000149011612) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.presto-domicon1 {
  top: 0px;
  left: 0px;
  width: 56px;
  height: 56px;
  position: absolute;
  border-radius: 6px;
}
 
.presto-text21 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame481911 {
  gap: 16px;
  width: auto;
  height: auto;
  display: flex;
  padding: 32px;
  overflow: hidden;
  box-shadow: 0px 16px 16px -18px rgba(0, 0, 0, 0.10000000149011612) ;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.presto-text22 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190212 {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
 
.presto-frame48190012 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logosafe {
  width: 56px;
  height: 56px;
}
 
.presto-text23 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190115 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logobiconomy {
  width: 56px;
  height: 56px;
}
 
.presto-text24 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190213 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logoden {
  width: 56px;
  height: 56px;
}
 
.presto-text25 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190013 {
  gap: 16px;
  width: auto;
  height: auto;
  display: flex;
  padding: 32px;
  overflow: hidden;
  box-shadow: 0px 16px 16px -18px rgba(0, 0, 0, 0.10000000149011612) ;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.presto-text26 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190214 {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
 
.presto-frame48190014 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logoblockscout {
  width: 56px;
  height: 56px;
}
 
.presto-text27 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190116 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logodora1 {
  width: 56px;
  height: 56px;
}
 
.presto-text28 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190117 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logodora2 {
  width: 56px;
  height: 56px;
}
 
.presto-text29 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame481905 {
  gap: 16px;
  width: auto;
  height: auto;
  display: flex;
  padding: 32px;
  overflow: hidden;
  box-shadow: 0px 16px 16px -18px rgba(0, 0, 0, 0.10000000149011612) ;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.presto-text30 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190215 {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
 
.presto-frame48190118 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logogevulot1 {
  width: 56px;
  height: 56px;
}
 
.presto-text31 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190119 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logogevulot2 {
  width: 56px;
  height: 56px;
}
 
.presto-text32 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190120 {
  gap: 16px;
  width: auto;
  height: auto;
  display: flex;
  padding: 32px;
  overflow: hidden;
  box-shadow: 0px 16px 16px -18px rgba(0, 0, 0, 0.10000000149011612) ;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.presto-text33 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190216 {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
 
.presto-frame48190015 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logoredstone1 {
  width: 56px;
  height: 56px;
}
 
.presto-text34 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190016 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logoredstone2 {
  width: 56px;
  height: 56px;
}
 
.presto-text35 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190217 {
  gap: 16px;
  width: auto;
  height: auto;
  display: flex;
  padding: 32px;
  overflow: hidden;
  box-shadow: 0px 16px 16px -18px rgba(0, 0, 0, 0.10000000149011612) ;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.presto-text36 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190218 {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
 
.presto-frame48190017 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logoaxelar {
  width: 56px;
  height: 56px;
}
 
.presto-text37 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190121 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logozkevm {
  width: 56px;
  height: 56px;
}
 
.presto-text38 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame481912 {
  gap: 16px;
  width: auto;
  height: auto;
  display: flex;
  padding: 32px;
  overflow: hidden;
  box-shadow: 0px 16px 16px -18px rgba(0, 0, 0, 0.10000000149011612) ;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.presto-text39 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190219 {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
 
.presto-frame48190018 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logosub7 {
  width: 56px;
  height: 56px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.presto-maskgroup {
  top: 6.125px;
  left: 9.625px;
  width: 36.75px;
  height: 43.75px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.presto-q-ixc-oq-ye-rmw-c85-rhv-bu4y-yhyou1 {
  top: 0px;
  left: 0px;
  width: 37px;
  height: 44px;
  position: absolute;
}
 
.presto-text40 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190122 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logoquantstamp {
  width: 56px;
  height: 56px;
}
 
.presto-text41 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190220 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logooxorio {
  width: 56px;
  height: 56px;
}
 
.presto-text42 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame4819032 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logoshieldifysecurity {
  width: 56px;
  height: 56px;
}
 
.presto-text43 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame4819033 {
  gap: 16px;
  width: auto;
  height: auto;
  display: flex;
  padding: 32px;
  overflow: hidden;
  box-shadow: 0px 16px 16px -18px rgba(0, 0, 0, 0.10000000149011612) ;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.presto-text44 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190221 {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
 
.presto-frame48190019 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logoaws {
  width: 56px;
  height: 56px;
}
 
.presto-text45 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190123 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logogooglecloudgcp {
  width: 56px;
  height: 56px;
}
 
.presto-text46 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190222 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logomicrosoftazure {
  width: 56px;
  height: 56px;
}
 
.presto-text47 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame4819034 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logobaremetal {
  width: 56px;
  height: 56px;
}
 
.presto-text48 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: Gotham;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame481899 {
  gap: 16px;
  width: auto;
  height: auto;
  display: flex;
  padding: 32px;
  overflow: hidden;
  box-shadow: 0px 16px 16px -18px rgba(0, 0, 0, 0.10000000149011612) ;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.presto-text49 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190223 {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
 
.presto-frame48190124 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logogoldsky1 {
  width: 56px;
  height: 56px;
}
 
.presto-text50 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190125 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logogoldsky2 {
  width: 56px;
  height: 56px;
}
 
.presto-text51 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame481907 {
  gap: 16px;
  width: auto;
  height: auto;
  display: flex;
  padding: 32px;
  overflow: hidden;
  box-shadow: 0px 16px 16px -18px rgba(0, 0, 0, 0.10000000149011612) ;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.presto-text52 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190224 {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
 
.presto-frame48190020 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logomagiclink {
  width: 56px;
  height: 56px;
}
 
.presto-text53 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190225 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logometamask {
  width: 56px;
  height: 56px;
}
 
.presto-text54 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame481908 {
  gap: 16px;
  width: auto;
  height: auto;
  display: flex;
  padding: 32px;
  overflow: hidden;
  box-shadow: 0px 16px 16px -18px rgba(0, 0, 0, 0.10000000149011612) ;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.presto-text55 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190226 {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
 
.presto-frame48190021 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logoamlbot {
  width: 56px;
  height: 56px;
}
 
.presto-text56 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-frame48190126 {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-logogatenox {
  width: 56px;
  height: 56px;
  display: flex;
  position: relative;
  box-shadow: 0px 16px 16px -12px rgba(0, 0, 0, 0.10000000149011612) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.20000000298023224);
}
 
.presto-z50v-l-hlz400x40021 {
  top: 0px;
  left: 0px;
  width: 56px;
  height: 56px;
  position: absolute;
  border-radius: 6px;
}
 
.presto-text57 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
 
.presto-container13 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.presto-container14 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-fourunits);
  align-items: center;
  flex-direction: row;
}
 
.presto-navlink10 {
  display: contents;
}
 
.presto-component24 {
  text-decoration: none;
}
 
.presto-navlink11 {
  display: contents;
}
 
.presto-component25 {
  text-decoration: none;
}
 
.presto-navlink12 {
  display: contents;
}
 
.presto-component26 {
  text-decoration: none;
}
 
.presto-container15 {
  width: 100%;
  display: none;
  align-items: center;
  flex-direction: column;
}
 
.presto-container16 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-fourunits);
  align-items: center;
  flex-direction: row;
}
 
.presto-navlink13 {
  display: contents;
}
 
.presto-component27 {
  text-decoration: none;
}
 
.presto-navlink14 {
  display: contents;
}
 
.presto-component28 {
  text-decoration: none;
}
 
.presto-navlink15 {
  display: contents;
}
 
.presto-component29 {
  text-decoration: none;
}
 
.presto-container17 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-fourunits);
  align-items: center;
  flex-direction: row;
}
 
.presto-navlink16 {
  display: contents;
}
 
.presto-component30 {
  text-decoration: none;
}
 
.presto-navlink17 {
  display: contents;
}
 
.presto-component31 {
  text-decoration: none;
}
 
.presto-navlink18 {
  display: contents;
}
 
.presto-component32 {
  text-decoration: none;
}
 
.presto-container18 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-fourunits);
  align-items: center;
  flex-direction: row;
}
 
.presto-navlink19 {
  display: contents;
}
 
.presto-component33 {
  text-decoration: none;
}
 
.presto-navlink20 {
  display: contents;
}
 
.presto-component34 {
  text-decoration: none;
}
 
.presto-container19 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: none;
  align-items: center;
  flex-direction: column;
}
 
.presto-container20 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-fourunits);
  align-items: center;
  flex-direction: row;
}
 
.presto-navlink21 {
  display: contents;
}
 
.presto-component38 {
  text-decoration: none;
}
 
.presto-navlink22 {
  display: contents;
}
 
.presto-component39 {
  text-decoration: none;
}
 
.presto-navlink23 {
  display: contents;
}
 
.presto-component40 {
  text-decoration: none;
}
 
.presto-container21 {
  width: 100%;
  align-items: center;
  flex-direction: column;
}
 
.presto-container22 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-fourunits);
  align-items: center;
  flex-direction: row;
}
 
.presto-navlink24 {
  display: contents;
}
 
.presto-component41 {
  text-decoration: none;
}
 
.presto-navlink25 {
  display: contents;
}
 
.presto-component42 {
  text-decoration: none;
}
 
.presto-navlink26 {
  display: contents;
}
 
.presto-component43 {
  text-decoration: none;
}
 
.presto-container23 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-fourunits);
  align-items: center;
  flex-direction: row;
}
 
.presto-navlink27 {
  display: contents;
}
 
.presto-component44 {
  text-decoration: none;
}
 
.presto-navlink28 {
  display: contents;
}
 
.presto-component45 {
  text-decoration: none;
}
 
.presto-navlink29 {
  display: contents;
}
 
.presto-component46 {
  text-decoration: none;
}
 
.presto-container24 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-fourunits);
  align-items: center;
  flex-direction: row;
}
 
.presto-navlink30 {
  display: contents;
}
 
.presto-component47 {
  text-decoration: none;
}
 
.presto-navlink31 {
  display: contents;
}
 
.presto-component48 {
  text-decoration: none;
}
 
.presto-navlink32 {
  display: contents;
}
 
.presto-component51 {
  text-decoration: none;
}
 
.presto-testimonials {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
 
@media(max-width: 1200px) {
  .presto-container10 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .presto-frame48190013 {
    width: 329px;
  }
  .presto-text29 {
    color: rgb(0, 0, 0);
  }
  .presto-container15 {
    display: flex;
  }
  .presto-container21 {
    display: flex;
  }
  .presto-testimonials {
    gap: var(--dl-space-space-unit);
    width: 100%;
    flex-direction: row;
  }
}
 
@media(max-width: 767px) {
  .presto-play1 {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: auto;
    align-items: center;
    justify-content: center;
  }
  .presto-testimonials {
    flex-direction: column;
  }
}
 
@media(max-width: 550px) {
  .presto-content1 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .presto-hero {
    padding-top: 80%;
    flex-direction: column-reverse;
    background-position: top;
  }
  .presto-description {
    width: 100%;
  }
  .presto-content2 {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
    justify-content: unset;
  }
  .presto-play1 {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: auto;
  }
  .presto-frame481909 {
    flex-wrap: wrap;
  }
  .presto-frame48190210 {
    flex-wrap: wrap;
  }
  .presto-frame481912 {
    flex-wrap: wrap;
  }
  .presto-frame48190219 {
    flex-wrap: wrap;
  }
  .presto-frame4819033 {
    flex-wrap: wrap;
  }
  .presto-frame48190221 {
    flex-wrap: wrap;
  }
  .presto-container14 {
    gap: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }
  .presto-container15 {
    display: none;
  }
  .presto-container16 {
    gap: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }
  .presto-container17 {
    gap: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }
  .presto-container18 {
    gap: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }
  .presto-container20 {
    gap: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }
  .presto-container21 {
    display: none;
  }
  .presto-container22 {
    gap: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }
  .presto-container23 {
    gap: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }
  .presto-container24 {
    gap: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }
  .presto-testimonials {
    flex-direction: column;
  }
}
