.bounties-container1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/neon-back-1500w.png");
}
 
.bounties-content {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 1126px;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
 
.bounties-hero {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
 
.bounties-description {
  gap: var(--dl-space-space-halfunit);
  width: 40%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.bounties-container2 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.bounties-text1 {
  color: rgb(0, 0, 0);
  width: 100%;
  height: auto;
  font-size: 18px;
  font-style: Book;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 300;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.bounties-link1 {
  display: contents;
}
 
.bounties-component13 {
  text-decoration: none;
}
 
.bounties-image {
  width: 50%;
  object-fit: cover;
}
 
.bounties-container3 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
 
.bounties-container4 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  max-width: 800px;
  align-items: flex-start;
  flex-direction: column;
}
 
.bounties-text2 {
  color: rgb(0, 0, 0);
  width: 100%;
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 300px;
  line-height: 28px;
  font-stretch: normal;
  letter-spacing: 0.5px;
}
 
.bounties-link2 {
  color: var(--dl-color-default-maininteractive);
  text-decoration: underline;
}
 
@media(max-width: 1200px) {
  .bounties-container1 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .bounties-text1 {
    color: rgb(0, 0, 0);
    font-size: 18px;
    font-family: Gotham-Book;
    font-weight: 300;
    line-height: 24px;
    text-decoration: none;
  }
}
 
@media(max-width: 550px) {
  .bounties-content {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .bounties-hero {
    flex-direction: column-reverse;
  }
  .bounties-description {
    width: 100%;
  }
  .bounties-image {
    width: 100%;
  }
}
