.brand-guidelines-container10 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/neon-back-1500w.png");
}
 
.brand-guidelines-container11 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 1126px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  flex-direction: column;
}
 
.brand-guidelines-container12 {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: 450px;
  align-items: flex-start;
  flex-direction: column;
}
 
.brand-guidelines-container13 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.brand-guidelines-link1 {
  display: contents;
}
 
.brand-guidelines-component13 {
  text-decoration: none;
}
 
.brand-guidelines-link2 {
  display: contents;
}
 
.brand-guidelines-component14 {
  text-decoration: none;
}
 
.brand-guidelines-container14 {
  box-shadow: 0px 4px 32px -12px rgba(0, 0, 0, 0.25);
  transition: 0.3s;
  border-radius: 29px;
  background-color: var(--dl-color-default-maintext);
}
 
.brand-guidelines-container14:hover {
  box-shadow: 0px 8px 32px -12px rgba(137, 80, 250, 0.5);
}
 
.brand-guidelines-container15 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: auto;
  display: flex;
  padding: 48px;
  overflow: hidden;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  text-decoration: none;
}
 
.brand-guidelines-download {
  gap: 89px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: flex-end;
}
 
.brand-guidelines-download-button {
  gap: 10px;
  display: flex;
  padding: 16px;
  align-items: flex-start;
  border-radius: 30px;
  background-color: var(--dl-color-default-maininteractive);
}
 
.brand-guidelines-trayarrowdown {
  width: 24px;
  height: 24px;
}
 
.brand-guidelines-image {
  width: auto;
  max-height: 57px;
  object-fit: cover;
}
 
.brand-guidelines-texts1 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.brand-guidelines-text10 {
  fill: var(--dl-color-default-secondarytext);
  color: var(--dl-color-default-secondarytext);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 18px;
  font-stretch: normal;
  text-decoration: none;
}
 
.brand-guidelines-text11 {
  fill: var(--dl-color-default-secondarytext);
  color: var(--dl-color-default-secondarytext);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Ultra;
  text-align: left;
  font-family: "Gotham-Black";
  font-weight: 400;
  line-height: 32px;
  font-stretch: normal;
  text-decoration: none;
}
 
.brand-guidelines-container16 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.brand-guidelines-link4 {
  display: contents;
}
 
.brand-guidelines-component15 {
  text-decoration: none;
}
 
.brand-guidelines-link5 {
  display: contents;
}
 
.brand-guidelines-component16 {
  text-decoration: none;
}
 
.brand-guidelines-link6 {
  display: contents;
}
 
.brand-guidelines-component17 {
  text-decoration: none;
}
 
.brand-guidelines-frame1171275240 {
  gap: 16px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.brand-guidelines-container17 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.brand-guidelines-container18 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.brand-guidelines-container19 {
  gap: 16px;
  width: 100%;
  height: auto;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.brand-guidelines-colour1 {
  width: 100%;
  height: 210px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  border-radius: 30px;
  background-color: var(--dl-color-default-maininteractive);
}
 
.brand-guidelines-texts2 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.brand-guidelines-text12 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: normal;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 700px;
  line-height: 24px;
  font-stretch: normal;
  letter-spacing: 0.15px;
}
 
.brand-guidelines-text13 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: normal;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 700px;
  line-height: 24px;
  font-stretch: normal;
  letter-spacing: 0.15px;
}
 
.brand-guidelines-container20 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.brand-guidelines-container21 {
  gap: 16px;
  width: 100%;
  height: auto;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.brand-guidelines-colour2 {
  width: 100%;
  height: 210px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  border-radius: 30px;
  background-color: #1A1A1A;
}
 
.brand-guidelines-texts3 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.brand-guidelines-text14 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: normal;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 700px;
  line-height: 24px;
  font-stretch: normal;
  letter-spacing: 0.15px;
}
 
.brand-guidelines-text15 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: normal;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 700px;
  line-height: 24px;
  font-stretch: normal;
  letter-spacing: 0.15px;
}
 
.brand-guidelines-container22 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.brand-guidelines-container23 {
  gap: 16px;
  width: 100%;
  height: auto;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.brand-guidelines-colour3 {
  width: 100%;
  height: 210px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  border-radius: 30px;
  background-color: #7EE100;
}
 
.brand-guidelines-texts4 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.brand-guidelines-text16 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: normal;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 700px;
  line-height: 24px;
  font-stretch: normal;
  letter-spacing: 0.15px;
}
 
.brand-guidelines-text17 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: normal;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 700px;
  line-height: 24px;
  font-stretch: normal;
  letter-spacing: 0.15px;
}
 
.brand-guidelines-container24 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.brand-guidelines-container25 {
  gap: 16px;
  width: 100%;
  height: auto;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.brand-guidelines-colour4 {
  width: 100%;
  height: 210px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  border-radius: 30px;
  background-color: #FA50C1;
}
 
.brand-guidelines-texts5 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.brand-guidelines-text18 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: normal;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 700px;
  line-height: 24px;
  font-stretch: normal;
  letter-spacing: 0.15px;
}
 
.brand-guidelines-text19 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: normal;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 700px;
  line-height: 24px;
  font-stretch: normal;
  letter-spacing: 0.15px;
}
 
@media(max-width: 550px) {
  .brand-guidelines-container11 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .brand-guidelines-container13 {
    flex-direction: column;
  }
  .brand-guidelines-container14 {
    width: 100%;
  }
  .brand-guidelines-container16 {
    flex-direction: column;
  }
  .brand-guidelines-frame1171275240 {
    flex-direction: column;
  }
  .brand-guidelines-container17 {
    flex-direction: column;
  }
}
