.usp-checkmark-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.usp-checkmark-content {
  gap: 16px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
 
.usp-checkmark-check {
  width: 24px;
  height: 24px;
}
 
.usp-checkmark-text {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 18px;
  font-style: Book;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 300;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 

 
@media(max-width: 1200px) {
  .usp-checkmarkroot-class-name {
    fill: var(--dl-color-theme-neutral-dark);
    color: var(--dl-color-theme-neutral-dark);
  }
}
