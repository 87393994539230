.blog-what-is-raa-s-rollup-as-a-service-container1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/neon-back-1500w.png");
}
 
.blog-what-is-raa-s-rollup-as-a-service-container2 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 1126px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  flex-direction: column;
}
 
.blog-what-is-raa-s-rollup-as-a-service-post {
  gap: 32px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.blog-what-is-raa-s-rollup-as-a-service-gatewaywirexcasestudy1 {
  width: 100%;
  height: auto;
  align-self: stretch;
  border-radius: 30px;
}
 
.blog-what-is-raa-s-rollup-as-a-service-text100 {
  gap: 32px;
  width: 100%;
  display: flex;
  max-width: 800px;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.blog-what-is-raa-s-rollup-as-a-service-container3 {
  gap: 16px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.blog-what-is-raa-s-rollup-as-a-service-tags {
  gap: 16px;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
}
 
.blog-what-is-raa-s-rollup-as-a-service-text101 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 32px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 40px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-what-is-raa-s-rollup-as-a-service-text102 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Book;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 300;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-what-is-raa-s-rollup-as-a-service-text103 {
  color: var(--dl-color-default-maintext);
  height: auto;
  align-self: stretch;
  text-align: left;
  font-stretch: normal;
}
 
.blog-what-is-raa-s-rollup-as-a-service-image1 {
  width: 100%;
  object-fit: cover;
}
 
.blog-what-is-raa-s-rollup-as-a-service-text224 {
  color: var(--dl-color-default-maintext);
  height: auto;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-stretch: normal;
}
 
.blog-what-is-raa-s-rollup-as-a-service-image2 {
  width: 100%;
  object-fit: cover;
}
 
.blog-what-is-raa-s-rollup-as-a-service-text255 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-what-is-raa-s-rollup-as-a-service-image3 {
  width: 100%;
  object-fit: cover;
}
 
.blog-what-is-raa-s-rollup-as-a-service-text264 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-what-is-raa-s-rollup-as-a-service-image4 {
  width: 100%;
  object-fit: cover;
}
 
.blog-what-is-raa-s-rollup-as-a-service-text271 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-what-is-raa-s-rollup-as-a-service-image5 {
  width: 100%;
  object-fit: cover;
}
 
.blog-what-is-raa-s-rollup-as-a-service-text278 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
}
 
@media(max-width: 1200px) {
  .blog-what-is-raa-s-rollup-as-a-service-gatewaywirexcasestudy1 {
    width: 100%;
    height: auto;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text101 {
    color: rgb(0, 0, 0);
  }
  .blog-what-is-raa-s-rollup-as-a-service-text102 {
    color: var(--dl-color-default-maintext);
    font-size: 16px;
    font-family: Gotham-Book;
    font-weight: 300;
    line-height: 24px;
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text103 {
    color: var(--dl-color-default-maintext);
  }
  .blog-what-is-raa-s-rollup-as-a-service-text116 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text117 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text120 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-link1 {
    font-size: 18px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 28px;
    text-transform: none;
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text174 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text175 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text176 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text178 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text179 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text181 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text182 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text183 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text185 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text186 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text188 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text189 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text190 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text192 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text193 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-link2 {
    font-size: 18px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 28px;
    text-transform: none;
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text196 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text197 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text198 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text200 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text201 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text203 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text204 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text205 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text207 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text208 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text210 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text211 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text212 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text214 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text215 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-link3 {
    font-size: 18px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 28px;
    text-transform: none;
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-link4 {
    font-size: 18px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 28px;
    text-transform: none;
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-link5 {
    font-size: 18px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 28px;
    text-transform: none;
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-link6 {
    font-size: 18px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 28px;
    text-transform: none;
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text221 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text222 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text223 {
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-image1 {
    width: 100%;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text224 {
    color: var(--dl-color-default-maintext);
  }
  .blog-what-is-raa-s-rollup-as-a-service-navlink1 {
    font-size: 18px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 28px;
    text-transform: none;
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-navlink2 {
    font-size: 18px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 28px;
    text-transform: none;
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-navlink3 {
    font-size: 18px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 28px;
    text-transform: none;
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-image2 {
    width: 100%;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text255 {
    color: var(--dl-color-default-maintext);
  }
  .blog-what-is-raa-s-rollup-as-a-service-image3 {
    width: 100%;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text264 {
    color: var(--dl-color-default-maintext);
  }
  .blog-what-is-raa-s-rollup-as-a-service-image4 {
    width: 100%;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text271 {
    color: var(--dl-color-default-maintext);
    font-size: 18px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 28px;
    text-decoration: none;
  }
  .blog-what-is-raa-s-rollup-as-a-service-image5 {
    width: 100%;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text278 {
    color: var(--dl-color-default-maintext);
    font-size: 18px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 28px;
  }
  .blog-what-is-raa-s-rollup-as-a-service-text280 {
    font-size: 24px;
  }
}
 
@media(max-width: 991px) {
  .blog-what-is-raa-s-rollup-as-a-service-container2 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
}
 
@media(max-width: 767px) {
  .blog-what-is-raa-s-rollup-as-a-service-container2 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
}
 
@media(max-width: 550px) {
  .blog-what-is-raa-s-rollup-as-a-service-container2 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .blog-what-is-raa-s-rollup-as-a-service-post {
    width: 100%;
  }
  .blog-what-is-raa-s-rollup-as-a-service-gatewaywirexcasestudy1 {
    height: auto;
  }
}
