.form-upload-upload {
  gap: 24px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 32px 56px;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  box-shadow: 0px 8px 24px -20px rgba(127, 127, 127, 0.5) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.30000001192092896);
}
 
.form-upload-container1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.form-upload-text1 {
  color: rgba(0, 0, 0, 1);
  width: 100%;
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 18px;
  font-stretch: normal;
  text-decoration: none;
}
 
.form-upload-form {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
 
.form-upload-container2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-direction: column;
}
 
.form-upload-upload-area {
  gap: 0px;
  display: flex;
  padding: 56px;
  overflow: hidden;
  align-self: stretch;
  box-shadow: 0px 8px 24px -20px rgba(127, 127, 127, 0.5) ;
  align-items: center;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.30000001192092896);
}
 
.form-upload-fileaccountoutline {
  width: 48px;
  height: 48px;
  opacity: 0.60;
}
 
.form-upload-ul {
  text-align: center;
  align-content: center;
}
 
.form-upload-text2 {
  color: rgba(0, 0, 0, 1);
  width: 100%;
  height: auto;
  opacity: 0.60;
  font-size: 18px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 700;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.form-upload-success {
  display: none;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.form-upload-image {
  width: 200px;
  object-fit: cover;
}
 
.form-upload-text3 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.form-uploadroot-class-name {
  flex: 1;
  width: auto;
}
 
.form-uploadroot-class-name1 {
  flex: 1;
  width: auto;
}
 
.form-uploadroot-class-name2 {
  flex: 1;
  width: auto;
}
 
.form-uploadroot-class-name3 {
  flex: 1;
  width: auto;
}
 
@media(max-width: 550px) {
  .form-upload-upload {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .form-uploadroot-class-name {
    width: 100%;
  }
  .form-uploadroot-class-name2 {
    width: 100%;
  }
  .form-uploadroot-class-name3 {
    width: 100%;
  }
}
