.card-description-compact-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.card-description-compact-frame1171275325 {
  gap: 68px;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  box-shadow: 0px 2px 4px 0px rgba(22, 23, 24, 0.07999999821186066) ;
  align-items: center;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
 
.card-description-compact-frame1171275346 {
  gap: 16px;
  width: 100%;
  display: flex;
  z-index: 2;
  align-items: flex-start;
  flex-direction: column;
}
 
.card-description-compact-frame-typography1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.card-description-compact-text1 {
  color: rgba(0, 0, 0, 1);
  width: 100%;
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 40px;
}
 
.card-description-compact-frame-typography2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.card-description-compact-text2 {
  color: rgba(0, 0, 0, 1);
  width: 100%;
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 24px;
}
 
.card-description-compact-text3 {
  display: inline-block;
}
 
.card-description-compact-text4 {
  display: inline-block;
}
 
.card-description-compactroot-class-name {
  background-color: rgba(255, 255, 255, 0.94);
}
 
.card-description-compactroot-class-name1 {
  background-color: rgba(255, 255, 255, 0.94);
}
 
.card-description-compactroot-class-name2 {
  background-color: rgba(255, 255, 255, 0.94);
}
