.subtitle-product-promo-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.subtitle-product-promo-text {
  color: rgba(0, 0, 0, 1);
  width: 100%;
  height: auto;
  font-size: 48px;
  font-style: Ultra;
  text-align: center;
  font-family: "Gotham-Ultra";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-transform: uppercase;
  text-decoration: none;
}
 
@media(max-width: 1200px) {
  .subtitle-product-promo-text {
    color: rgb(0, 0, 0);
  }
}
 
@media(max-width: 550px) {
  .subtitle-product-promo-text {
    font-size: 36px;
  }
}
