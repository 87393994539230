.blog-container1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/neon-back-1500w.png");
}
 
.blog-container2 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
 
.blog-container3 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 1126px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  flex-direction: column;
}
 
.blog-navlink10 {
  display: contents;
}
 
.blog-component12 {
  text-decoration: none;
}
 
.blog-text10 {
  display: inline-block;
}
 
.blog-row10 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.blog-navlink11 {
  display: contents;
}
 
.blog-component13 {
  text-decoration: none;
}
 
.blog-text13 {
  display: inline-block;
}
 
.blog-text14 {
  height: auto;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-stretch: normal;
}
 
.blog-navlink12 {
  display: contents;
}
 
.blog-component14 {
  text-decoration: none;
}
 
.blog-text16 {
  display: inline-block;
}
 
.blog-row11 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.blog-component15 {
  text-decoration: none;
}
 
.blog-text19 {
  display: inline-block;
}
 
.blog-text20 {
  height: auto;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-stretch: normal;
}
 
.blog-component16 {
  text-decoration: none;
}
 
.blog-text22 {
  display: inline-block;
}
 
.blog-row12 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.blog-navlink13 {
  display: contents;
}
 
.blog-component17 {
  text-decoration: none;
}
 
.blog-text25 {
  display: inline-block;
}
 
.blog-text26 {
  height: auto;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-stretch: normal;
}
 
.blog-navlink14 {
  display: contents;
}
 
.blog-component18 {
  text-decoration: none;
}
 
.blog-text28 {
  display: inline-block;
}
 
.blog-row13 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.blog-navlink15 {
  display: contents;
}
 
.blog-component19 {
  text-decoration: none;
}
 
.blog-text31 {
  display: inline-block;
}
 
.blog-text32 {
  height: auto;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-stretch: normal;
}
 
.blog-navlink16 {
  display: contents;
}
 
.blog-component20 {
  text-decoration: none;
}
 
.blog-text34 {
  display: inline-block;
}
 
.blog-row14 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.blog-navlink17 {
  display: contents;
}
 
.blog-component21 {
  text-decoration: none;
}
 
.blog-text37 {
  display: inline-block;
}
 
.blog-text38 {
  height: auto;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-stretch: normal;
}
 
.blog-navlink18 {
  display: contents;
}
 
.blog-component22 {
  text-decoration: none;
}
 
.blog-text40 {
  display: inline-block;
}
 
.blog-row15 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.blog-navlink19 {
  display: contents;
}
 
.blog-component23 {
  text-decoration: none;
}
 
.blog-text43 {
  display: inline-block;
}
 
.blog-navlink20 {
  display: contents;
}
 
.blog-component24 {
  text-decoration: none;
}
 
.blog-text44 {
  display: inline-block;
}
 
.blog-row16 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.blog-navlink21 {
  display: contents;
}
 
.blog-component25 {
  text-decoration: none;
}
 
.blog-text47 {
  display: inline-block;
}
 
.blog-navlink22 {
  display: contents;
}
 
.blog-component26 {
  text-decoration: none;
}
 
.blog-text48 {
  display: inline-block;
}
 
.blog-row17 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.blog-navlink23 {
  display: contents;
}
 
.blog-component27 {
  text-decoration: none;
}
 
.blog-text51 {
  display: inline-block;
}
 
.blog-navlink24 {
  display: contents;
}
 
.blog-component28 {
  text-decoration: none;
}
 
.blog-text52 {
  display: inline-block;
}
 
.blog-row18 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.blog-navlink25 {
  display: contents;
}
 
.blog-component29 {
  text-decoration: none;
}
 
.blog-text53 {
  display: inline-block;
}
 
.blog-navlink26 {
  display: contents;
}
 
.blog-component30 {
  text-decoration: none;
}
 
.blog-text54 {
  display: inline-block;
}
 
.blog-row19 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.blog-navlink27 {
  display: contents;
}
 
.blog-component31 {
  text-decoration: none;
}
 
.blog-text55 {
  display: inline-block;
}
 
.blog-navlink28 {
  display: contents;
}
 
.blog-component32 {
  text-decoration: none;
}
 
.blog-text56 {
  display: inline-block;
}
 
.blog-row20 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.blog-navlink29 {
  display: contents;
}
 
.blog-component33 {
  text-decoration: none;
}
 
.blog-text57 {
  display: inline-block;
}
 
.blog-navlink30 {
  display: contents;
}
 
.blog-component34 {
  text-decoration: none;
}
 
.blog-text58 {
  display: inline-block;
}
 
.blog-container4 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.blog-navlink31 {
  display: contents;
}
 
.blog-component35 {
  text-decoration: none;
}
 
.blog-text59 {
  display: inline-block;
}
 
.blog-navlink32 {
  display: contents;
}
 
.blog-component36 {
  text-decoration: none;
}
 
.blog-text61 {
  display: inline-block;
}
 
.blog-container5 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.blog-navlink33 {
  display: contents;
}
 
.blog-component37 {
  text-decoration: none;
}
 
.blog-text62 {
  display: inline-block;
}
 
.blog-navlink34 {
  display: contents;
}
 
.blog-component38 {
  text-decoration: none;
}
 
.blog-text63 {
  display: inline-block;
}
 
.blog-container6 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.blog-navlink35 {
  display: contents;
}
 
.blog-component39 {
  text-decoration: none;
}
 
.blog-text64 {
  display: inline-block;
}
 
.blog-navlink36 {
  display: contents;
}
 
.blog-component40 {
  text-decoration: none;
}
 
.blog-text65 {
  display: inline-block;
}
 
.blog-navlink37 {
  display: contents;
}
 
.blog-component41 {
  text-decoration: none;
}
 
.blog-text66 {
  display: inline-block;
}
 
.blog-row21 {
  gap: var(--dl-space-space-twounits);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
@media(max-width: 1200px) {
  .blog-container2 {
    box-shadow: 5px 5px 10px 0px #d4d4d4;
  }
  .blog-container3 {
    position: relative;
  }
}
 
@media(max-width: 550px) {
  .blog-container2 {
    width: 100%;
  }
  .blog-container3 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .blog-row10 {
    width: 100%;
    flex-direction: column;
  }
  .blog-row11 {
    width: 100%;
    flex-direction: column;
  }
  .blog-row12 {
    width: 100%;
    flex-direction: column;
  }
  .blog-row13 {
    width: 100%;
    flex-direction: column;
  }
  .blog-row14 {
    width: 100%;
    flex-direction: column;
  }
  .blog-row15 {
    width: 100%;
    flex-direction: column;
  }
  .blog-row16 {
    width: 100%;
    flex-direction: column;
  }
  .blog-row17 {
    width: 100%;
    flex-direction: column;
  }
  .blog-row18 {
    width: 100%;
    flex-direction: column;
  }
  .blog-row19 {
    width: 100%;
    flex-direction: column;
  }
  .blog-row20 {
    width: 100%;
    flex-direction: column;
  }
  .blog-container4 {
    flex-direction: column;
  }
  .blog-container5 {
    flex-direction: column;
  }
  .blog-container6 {
    flex-direction: column;
  }
  .blog-row21 {
    width: 100%;
    flex-direction: column;
  }
}
