.subtext-product-promo-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
 
.subtext-product-promo-text {
  color: rgb(0, 0, 0);
  width: 100%;
  height: auto;
  font-size: 20px;
  max-width: 500px;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
