.content-title-product-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.content-title-product-text {
  color: rgba(0, 0, 0, 1);
  width: 100%;
  height: auto;
  font-size: 36px;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700px;
  line-height: 40px;
  font-stretch: normal;
  letter-spacing: 0.25px;
}
 
.content-title-productroot-class-name {
  max-width: 800px;
}
 
.content-title-productroot-class-name1 {
  max-width: 800px;
}
 
.content-title-productroot-class-name2 {
  max-width: 800px;
}
 
.content-title-productroot-class-name3 {
  max-width: 800px;
}
