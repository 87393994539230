.content-number-container1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.content-number-container2 {
  width: 100%;
  height: auto;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.content-number-text1 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 56px;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Black";
  font-weight: 700;
  line-height: 64px;
  font-stretch: normal;
  text-decoration: none;
}
 
.content-number-subtext {
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.content-number-text2 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 16px;
  font-style: Book;
  text-align: center;
  font-family: "Gotham-Book";
  font-weight: 300;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
@media(max-width: 1200px) {
  .content-number-text1 {
    color: rgb(0, 0, 0);
    font-size: 56px;
    font-family: Gotham-Black;
    font-weight: 700;
    line-height: 64px;
    text-decoration: none;
  }
}
