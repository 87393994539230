.blog-indexer-by-gatewayfm-container1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/neon-back-1500w.png");
}
 
.blog-indexer-by-gatewayfm-container2 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 1126px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  flex-direction: column;
}
 
.blog-indexer-by-gatewayfm-post {
  gap: 32px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.blog-indexer-by-gatewayfm-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}
 
.blog-indexer-by-gatewayfm-text10 {
  gap: 32px;
  width: 100%;
  display: flex;
  max-width: 800px;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.blog-indexer-by-gatewayfm-container3 {
  gap: 16px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.blog-indexer-by-gatewayfm-tags {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
 
.blog-indexer-by-gatewayfm-text11 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 32px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 40px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-indexer-by-gatewayfm-text12 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Book;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 300;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-indexer-by-gatewayfm-text13 {
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
}
 
.blog-indexer-by-gatewayfm-text14 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text19 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text20 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text21 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text22 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text23 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text24 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text25 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text26 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text27 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text28 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text29 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text30 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text31 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text32 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text33 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text34 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text35 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text36 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text37 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text38 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text39 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text40 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text41 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text42 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text43 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text44 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text45 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text47 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text48 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text49 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text50 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text51 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text52 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text53 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text54 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text55 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text56 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text57 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text59 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text60 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text61 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text62 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text63 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text65 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text66 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text67 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text69 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text70 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text71 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text73 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text74 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text75 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text76 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text78 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text79 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text80 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text81 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text82 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text83 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text85 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-navlink {
  color: var(--dl-color-default-maininteractive);
  text-decoration: underline;
}
 
.blog-indexer-by-gatewayfm-text86 {
  color: var(--dl-color-default-maininteractive);
}
 
.blog-indexer-by-gatewayfm-text87 {
  color: var(--dl-color-default-maintext);
}
 
.blog-indexer-by-gatewayfm-text88 {
  color: var(--dl-color-default-maintext);
}
 
@media(max-width: 1200px) {
  .blog-indexer-by-gatewayfm-text11 {
    color: rgb(0, 0, 0);
    font-size: 32px;
    font-family: Gotham-Bold;
    font-weight: 700;
    line-height: 40px;
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text12 {
    color: var(--dl-color-default-maintext);
    font-size: 16px;
    font-family: Gotham-Book;
    font-weight: 300;
    line-height: 24px;
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text13 {
    color: var(--dl-color-default-maintext);
  }
  .blog-indexer-by-gatewayfm-text19 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text20 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text21 {
    font-family: Gotham-Bold;
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text22 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text23 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text24 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text25 {
    font-family: Gotham-Bold;
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text26 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text27 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text28 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text29 {
    font-family: Gotham-Bold;
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text30 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text31 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text32 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text33 {
    font-family: Gotham-Bold;
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text34 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text35 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text36 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text37 {
    font-family: Gotham-Bold;
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text38 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text39 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text40 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text41 {
    font-family: Gotham-Bold;
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text42 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text43 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text44 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text45 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text47 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text48 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text49 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text50 {
    color: var(--dl-color-default-maininteractive);
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text51 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text52 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text53 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text54 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text55 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text56 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text57 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text59 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text60 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text61 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text62 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text63 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text65 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text66 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text67 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text69 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text70 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text71 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text73 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text74 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text75 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text76 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text78 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text79 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text80 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text81 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text82 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text83 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text84 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text85 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-navlink {
    color: var(--dl-color-default-maininteractive);
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text86 {
    color: var(--dl-color-default-maininteractive);
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text87 {
    text-decoration: none;
  }
  .blog-indexer-by-gatewayfm-text88 {
    text-decoration: none;
  }
}
 
@media(max-width: 991px) {
  .blog-indexer-by-gatewayfm-container2 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
}
 
@media(max-width: 767px) {
  .blog-indexer-by-gatewayfm-container2 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
}
 
@media(max-width: 550px) {
  .blog-indexer-by-gatewayfm-container2 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .blog-indexer-by-gatewayfm-post {
    width: 100%;
  }
}
