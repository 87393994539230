.card-scheme-container1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.card-scheme-container2 {
  width: 100%;
  height: auto;
  display: flex;
  padding: 48px;
  overflow: hidden;
  box-shadow: 0px 4px 32px -12px rgba(0, 122, 255, 0.5) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 2px 0 0 2px;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.5);
}
 
.card-scheme-texts {
  gap: var(--dl-space-space-unit);
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.card-scheme-text1 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 40px;
  font-style: Black;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 900;
  line-height: 48px;
  font-stretch: normal;
  text-decoration: none;
}
 
.card-scheme-tags {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
 
.card-scheme-tag1 {
  gap: 10px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
 
.card-scheme-text2 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 16px;
  font-style: Book;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 300;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.card-scheme-tag2 {
  gap: 10px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
 
.card-scheme-text3 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 16px;
  font-style: Book;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 300;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.card-scheme-text4 {
  color: rgba(0, 0, 0, 1);
  width: 50%;
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Book;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 300;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
@media(max-width: 550px) {
  .card-scheme-container2 {
    gap: 24px;
    padding: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: unset;
  }
  .card-scheme-texts {
    width: 100%;
  }
  .card-scheme-text1 {
    width: 100%;
  }
  .card-scheme-text4 {
    width: 100%;
  }
}
