.web3-infrastructure-blueprint-dao-container1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  background-size: cover;
  background-image: url("/external/neon-back-1500w.png");
}
 
.web3-infrastructure-blueprint-dao-content {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 1126px;
  align-items: center;
  flex-direction: column;
}
 
.web3-infrastructure-blueprint-dao-hero {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
 
.web3-infrastructure-blueprint-dao-description {
  gap: var(--dl-space-space-halfunit);
  width: 421px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.web3-infrastructure-blueprint-dao-navlink1 {
  display: contents;
}
 
.web3-infrastructure-blueprint-dao-component17 {
  text-decoration: none;
}
 
.web3-infrastructure-blueprint-dao-container2 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-sixunits);
  padding-right: var(--dl-space-space-sixunits);
  flex-direction: column;
}
 
.web3-infrastructure-blueprint-dao-cards {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
 
.web3-infrastructure-blueprint-dao-testimonials {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
 
.web3-infrastructure-blueprint-dao-navlink2 {
  display: contents;
}
 
.web3-infrastructure-blueprint-dao-component51 {
  text-decoration: none;
}
 
@media(max-width: 1200px) {
  .web3-infrastructure-blueprint-dao-container1 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .web3-infrastructure-blueprint-dao-testimonials {
    gap: var(--dl-space-space-unit);
    width: 100%;
    flex-direction: row;
  }
}
 
@media(max-width: 767px) {
  .web3-infrastructure-blueprint-dao-testimonials {
    flex-direction: column;
  }
}
 
@media(max-width: 550px) {
  .web3-infrastructure-blueprint-dao-container1 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .web3-infrastructure-blueprint-dao-content {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .web3-infrastructure-blueprint-dao-hero {
    flex-direction: column-reverse;
  }
  .web3-infrastructure-blueprint-dao-description {
    width: 100%;
  }
  .web3-infrastructure-blueprint-dao-container2 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .web3-infrastructure-blueprint-dao-cards {
    flex-direction: column;
  }
  .web3-infrastructure-blueprint-dao-testimonials {
    flex-direction: column;
  }
}
