.rpc-container {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  background-size: cover;
  background-image: url("/external/neon-back-1500w.png");
}
 
.rpc-content {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: 1126px;
  align-items: center;
  flex-direction: column;
}
 
.rpc-hero {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
 
.rpc-description {
  gap: var(--dl-space-space-halfunit);
  width: 40%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.rpc-link {
  display: contents;
}
 
.rpc-component16 {
  text-decoration: none;
}
 
.rpc-image {
  width: 50%;
  object-fit: cover;
}
 
.rpc-chains-container {
  gap: 24px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  overflow-x: scroll;
  align-items: center;
  flex-shrink: 0;
}
 
.rpc-row1 {
  gap: 32px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: center;
}
 
.rpc-row2 {
  gap: 32px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: center;
}
 
.rpc-numbers {
  gap: 32px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.rpc-testimonials {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
 
@media(max-width: 1200px) {
  .rpc-container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .rpc-testimonials {
    gap: var(--dl-space-space-unit);
    width: 100%;
    flex-direction: row;
  }
}
 
@media(max-width: 767px) {
  .rpc-testimonials {
    flex-direction: column;
  }
}
 
@media(max-width: 550px) {
  .rpc-container {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .rpc-content {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .rpc-hero {
    flex-direction: column-reverse;
  }
  .rpc-description {
    width: 100%;
  }
  .rpc-image {
    width: 100%;
  }
  .rpc-row1 {
    flex-direction: column;
  }
  .rpc-row2 {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .rpc-numbers {
    flex-direction: column;
  }
  .rpc-testimonials {
    flex-direction: column;
  }
}
