.card-description-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.card-description-frame1171275325 {
  gap: 68px;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  box-shadow: 0px 2px 4px 0px rgba(22, 23, 24, 0.07999999821186066) ;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.card-description-frame1171275345 {
  gap: 10px;
  display: flex;
  z-index: 1;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: flex-end;
}
 
.card-description-frame-icon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
 
.card-description-arrow-right {
  width: 32px;
  height: 32px;
}
 
.card-description-frame1171275346 {
  gap: 16px;
  width: 100%;
  display: flex;
  z-index: 2;
  align-items: flex-start;
  flex-direction: column;
}
 
.card-description-frame-typography1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.card-description-text1 {
  color: rgba(0, 0, 0, 1);
  width: 100%;
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 40px;
}
 
.card-description-frame-typography2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.card-description-text2 {
  color: rgba(0, 0, 0, 1);
  width: 100%;
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 24px;
}
 
.card-description-text3 {
  display: inline-block;
}
 
.card-description-text4 {
  display: inline-block;
}
 
.card-descriptionroot-class-name3 {
  background-size: cover;
  background-image: url("/external/images/backgr-1-200h.png");
}
 
.card-descriptionroot-class-name4 {
  background-size: cover;
  background-image: url("/external/images/backgr-2-200h.png");
}
 
.card-descriptionroot-class-name5 {
  background-size: cover;
  background-image: url("/external/images/backgr-3-200h.png");
}
