.home-container1 {
  gap: 48px;
  width: 100%;
  display: flex;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
}
 
.home-hero-space {
  gap: 32px;
  width: 788px;
  height: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-alert {
  gap: 16px;
  width: auto;
  height: auto;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  border-radius: 96px;
  background-color: rgba(98, 98, 98, 0.10000000149011612);
}
 
.home-container2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
}
 
.home-institution-logo-assets1 {
  width: 32px;
  height: 32px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  background-color: rgba(18, 18, 18, 1);
}
 
.home-linea11 {
  top: 7px;
  left: 8px;
  width: 17px;
  height: 18px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.home-clippathgroup1 {
  top: 0.2944164276123047px;
  left: 0px;
  width: 16.468013763427734px;
  height: 17.262338638305664px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-clip016424701 {
  top: 0px;
  left: 0px;
  width: 16.468013763427734px;
  height: 17.262338638305664px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector100 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 17px;
  position: absolute;
}
 
.home-group10 {
  top: 0.000030994415283203125px;
  left: 0px;
  width: 16.468013763427734px;
  height: 17.262372970581055px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector101 {
  top: 2.801119565963745px;
  left: 0px;
  width: 14px;
  height: 14px;
  position: absolute;
}
 
.home-vector102 {
  top: -0.000013560056686401367px;
  left: 10.885382652282715px;
  width: 6px;
  height: 6px;
  position: absolute;
}
 
.home-institution-logo-assets2 {
  width: 32px;
  height: 32px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.home-arbitrumarblogo11 {
  top: 1px;
  left: 1px;
  width: 30px;
  height: 30px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.home-layerx002011 {
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-frame24055884772321 {
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-group11 {
  top: 0px;
  left: 1.7039998769760132px;
  width: 26.591999053955078px;
  height: 29.975997924804688px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-group12 {
  top: 0px;
  left: 0px;
  width: 26.591999053955078px;
  height: 29.975997924804688px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector103 {
  top: 1.1369999647140503px;
  left: 1.0080000162124634px;
  width: 25px;
  height: 28px;
  position: absolute;
}
 
.home-group13 {
  top: 10.42199993133545px;
  left: 14.036999702453613px;
  width: 9.458999633789062px;
  height: 18.0419979095459px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-group14 {
  top: 0px;
  left: 0px;
  width: 9.458999633789062px;
  height: 18.0419979095459px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-group15 {
  top: 6.695999622344971px;
  left: 4.205703305615316e-7px;
  width: 5.4150004386901855px;
  height: 11.346000671386719px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector104 {
  top: 0px;
  left: 0px;
  width: 5px;
  height: 11px;
  position: absolute;
}
 
.home-group16 {
  top: 6.866456203624693e-8px;
  left: 2.448000431060791px;
  width: 7.01099967956543px;
  height: 15.714000701904297px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector105 {
  top: 0px;
  left: 0px;
  width: 7px;
  height: 16px;
  position: absolute;
}
 
.home-group17 {
  top: 0px;
  left: 0px;
  width: 26.591999053955078px;
  height: 29.975997924804688px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector106 {
  top: 0px;
  left: 0px;
  width: 27px;
  height: 30px;
  position: absolute;
}
 
.home-vector107 {
  top: 23.363998413085938px;
  left: 6.000000476837158px;
  width: 3px;
  height: 3px;
  position: absolute;
}
 
.home-group18 {
  top: 7.716000080108643px;
  left: 3.1080002784729004px;
  width: 14.39167594909668px;
  height: 20.748003005981445px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector108 {
  top: 0px;
  left: 0px;
  width: 10px;
  height: 18px;
  position: absolute;
}
 
.home-vector109 {
  top: -8.010863439267268e-8px;
  left: 4.043999671936035px;
  width: 10px;
  height: 21px;
  position: absolute;
}
 
.home-text100 {
  color: rgba(22, 23, 24, 1);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-frame-button10 {
  gap: 8px;
  display: flex;
  padding: 4px 12px;
  align-items: center;
  border-color: rgba(229, 231, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 96px;
  justify-content: center;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-text101 {
  color: rgba(22, 23, 24, 1);
  height: auto;
  text-align: center;
  line-height: 24px;
  white-space: nowrap;
}
 
.home-right-icon1 {
  display: flex;
  align-items: center;
}
 
.home-arrow-right1 {
  width: 16px;
  height: 16px;
}
 
.home-headline {
  gap: 32px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.home-content1 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.home-container3 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.home-text102 {
  color: rgb(22, 23, 24);
  width: 788px;
  height: auto;
  font-size: 72px;
  font-style: Bold;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 700;
  line-height: 72px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-text103 {
  color: #161718;
}
 
.home-text105 {
  fill: var(--dl-color-default-maininteractive);
  color: var(--dl-color-default-maininteractive);
  width: 788px;
  font-size: 72px;
  font-style: Bold;
  min-height: 72px;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 700;
  line-height: 72px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-frame481591 {
  gap: 12px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.home-text106 {
  color: rgb(58, 60, 63);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Book;
  text-align: center;
  font-family: "Gotham-Book";
  font-weight: 300;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-frame4815971 {
  gap: 16px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
 
.home-frame-button11 {
  gap: 16px;
  display: flex;
  padding: 12px 24px;
  align-items: center;
  border-color: rgba(229, 231, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 96px;
  justify-content: center;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-left-icon1 {
  display: flex;
  align-items: center;
}
 
.home-calendar1 {
  width: 24px;
  height: 24px;
}
 
.home-text107 {
  color: rgba(22, 23, 24, 1);
  height: auto;
  text-align: center;
  line-height: 32px;
}
 
.home-frame-button12 {
  gap: 16px;
  display: flex;
  padding: 12px 24px;
  background: linear-gradient(180deg, rgba(137, 80, 250, 1) 0%, rgba(129, 53, 242, 1) 100%);
  box-shadow: 0px 2px 4px 0px rgba(48, 14, 103, 0.23999999463558197) ;
  align-items: center;
  border-color: rgba(137, 80, 250, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 96px;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-gradient-primary);
}
 
.home-text108 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  text-align: center;
  line-height: 32px;
}
 
.home-right-icon2 {
  display: flex;
  align-items: center;
}
 
.home-arrow-right2 {
  width: 24px;
  height: 24px;
}
 
.home-privacy {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
 
.home-content2 {
  gap: 16px;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.home-chip1 {
  height: 40px;
  display: flex;
  padding: 8px 12px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background-color: rgba(238, 232, 255, 1);
}
 
.home-left-icon2 {
  display: flex;
  align-items: center;
}
 
.home-lock {
  width: 24px;
  height: 24px;
}
 
.home-label-container1 {
  gap: 8px;
  display: flex;
  padding: 0 8px;
  align-items: center;
  justify-content: center;
}
 
.home-text109 {
  color: rgb(48, 14, 103);
  height: auto;
  font-size: 14px;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-frame-typography10 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-text110 {
  color: rgba(22, 23, 24, 1);
  height: auto;
  text-align: left;
  line-height: 64px;
  font-stretch: normal;
}
 
.home-frame-typography11 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-text114 {
  color: rgba(58, 60, 63, 1);
  height: auto;
  text-align: left;
  line-height: 24px;
}
 
.home-frame4815972 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
 
.home-frame-button13 {
  gap: 8px;
  display: flex;
  padding: 8px 16px;
  box-shadow: 0px 2px 4px 0px rgba(22, 23, 24, 0.23999999463558197) ;
  transition: 0.3s;
  align-items: center;
  border-color: rgba(58, 60, 63, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 96px;
  justify-content: center;
  text-decoration: none;
  background-color: rgba(22, 23, 24, 1);
}
 
.home-frame-button13:hover {
  background-color: #2b2b2d;
}
 
.home-text117 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  text-align: center;
  line-height: 24px;
}
 
.home-frame-button14 {
  gap: 8px;
  display: flex;
  padding: 8px 16px;
  background: linear-gradient(180deg, rgba(137, 80, 250, 1) 0%, rgba(129, 53, 242, 1) 100%);
  box-shadow: 0px 2px 4px 0px rgba(48, 14, 103, 0.23999999463558197) ;
  transition: 0.3s;
  align-items: center;
  border-color: rgba(137, 80, 250, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 96px;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-gradient-primary);
}
 
.home-frame-button14:hover {
  background-color: #601ed4;
}
 
.home-text118 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  text-align: center;
  line-height: 24px;
}
 
.home-right-icon3 {
  display: flex;
  align-items: center;
}
 
.home-arrow-right3 {
  width: 24px;
  height: 24px;
}
 
.home-image1 {
  width: 600px;
  object-fit: cover;
}
 
.home-enterprise-cards1 {
  gap: 24px;
  width: 1229px;
  height: auto;
  display: flex;
  margin-top: -128px;
  align-items: center;
  flex-shrink: 0;
  margin-bottom: 0px;
}
 
.home-navlink1 {
  display: contents;
}
 
.home-component2 {
  text-decoration: none;
}
 
.home-text119 {
  display: inline-block;
}
 
.home-text120 {
  display: inline-block;
}
 
.home-navlink2 {
  display: contents;
}
 
.home-component3 {
  text-decoration: none;
}
 
.home-text121 {
  display: inline-block;
}
 
.home-text122 {
  display: inline-block;
}
 
.home-navlink3 {
  display: contents;
}
 
.home-component4 {
  text-decoration: none;
}
 
.home-text123 {
  display: inline-block;
}
 
.home-text124 {
  display: inline-block;
}
 
.home-excellence {
  width: 100%;
  height: auto;
  display: flex;
  overflow: visible;
  max-width: 1200px;
  align-self: center;
  margin-top: -156px;
  align-items: flex-start;
  margin-bottom: -156px;
  flex-direction: row;
  justify-content: space-between;
}
 
.home-content3 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.home-chip2 {
  height: 40px;
  display: flex;
  padding: 8px 12px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background-color: rgba(238, 232, 255, 1);
}
 
.home-left-icon3 {
  display: flex;
  align-items: center;
}
 
.home-lightning {
  width: 24px;
  height: 24px;
}
 
.home-label-container2 {
  gap: 8px;
  display: flex;
  padding: 0 8px;
  align-items: center;
  justify-content: center;
}
 
.home-text125 {
  color: rgba(48, 14, 103, 1);
  height: auto;
  font-size: 14px;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.home-frame-typography12 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-text126 {
  color: rgba(22, 23, 24, 1);
  height: auto;
  text-align: left;
  line-height: 64px;
}
 
.home-frame481494 {
  gap: -4px;
  display: flex;
  align-items: flex-start;
}
 
.home-logopolygon1 {
  width: 24px;
  height: 24px;
}
 
.home-logolinea1 {
  width: 24px;
  height: 24px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 24px;
  background-color: rgba(18, 18, 18, 1);
}
 
.home-linea12 {
  top: 5.25px;
  left: 6px;
  width: 13px;
  height: 13.5px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.home-clippathgroup2 {
  top: 0.22081232070922852px;
  left: 0px;
  width: 12.3510103225708px;
  height: 12.946754455566406px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-clip016424702 {
  top: 0px;
  left: 0px;
  width: 12.3510103225708px;
  height: 12.946754455566406px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector110 {
  top: 0px;
  left: 0px;
  width: 12px;
  height: 13px;
  position: absolute;
}
 
.home-group19 {
  top: 0.000030994415283203125px;
  left: 0px;
  width: 12.3510103225708px;
  height: 12.946779251098633px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector111 {
  top: 2.100831985473633px;
  left: 0px;
  width: 10px;
  height: 11px;
  position: absolute;
}
 
.home-vector112 {
  top: -0.000017911195755004883px;
  left: 8.164036750793457px;
  width: 4px;
  height: 4px;
  position: absolute;
}
 
.home-logooptimism1 {
  width: 24px;
  height: 24px;
}
 
.home-logoarbitrum1 {
  width: 24px;
  height: 24px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 24px;
}
 
.home-arbitrumarblogo12 {
  top: 0.75px;
  left: 0.75px;
  width: 22px;
  height: 22.5px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.home-layerx002012 {
  top: 0px;
  left: 0px;
  width: 22.5px;
  height: 22.5px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-frame24055884772322 {
  top: 0px;
  left: 0px;
  width: 22.5px;
  height: 22.5px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-group20 {
  top: 0px;
  left: 1.2779998779296875px;
  width: 19.944000244140625px;
  height: 22.481998443603516px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-group21 {
  top: 0px;
  left: 0px;
  width: 19.944000244140625px;
  height: 22.481998443603516px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector113 {
  top: 0.8527499437332153px;
  left: 0.7560000419616699px;
  width: 18px;
  height: 21px;
  position: absolute;
}
 
.home-group22 {
  top: 7.816499710083008px;
  left: 10.527750015258789px;
  width: 7.094249725341797px;
  height: 13.531497955322266px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-group23 {
  top: 0px;
  left: 0px;
  width: 7.094249725341797px;
  height: 13.531497955322266px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-group24 {
  top: 5.022000312805176px;
  left: 0px;
  width: 4.061250686645508px;
  height: 8.509499549865723px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector114 {
  top: 0px;
  left: 0px;
  width: 4px;
  height: 9px;
  position: absolute;
}
 
.home-group25 {
  top: 4.76837158203125e-7px;
  left: 1.8360004425048828px;
  width: 5.258249282836914px;
  height: 11.785501480102539px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector115 {
  top: 0px;
  left: 0px;
  width: 5px;
  height: 12px;
  position: absolute;
}
 
.home-group26 {
  top: 0px;
  left: 0px;
  width: 19.944000244140625px;
  height: 22.481998443603516px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector116 {
  top: 0px;
  left: 0px;
  width: 20px;
  height: 22px;
  position: absolute;
}
 
.home-vector117 {
  top: 17.522998809814453px;
  left: 4.500000476837158px;
  width: 2px;
  height: 3px;
  position: absolute;
}
 
.home-group27 {
  top: 5.7870001792907715px;
  left: 2.331000328063965px;
  width: 10.793756484985352px;
  height: 15.56100082397461px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector118 {
  top: 0px;
  left: 0px;
  width: 7px;
  height: 14px;
  position: absolute;
}
 
.home-vector119 {
  top: 0px;
  left: 3.0329995155334473px;
  width: 8px;
  height: 16px;
  position: absolute;
}
 
.home-frame-typography13 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-text130 {
  color: rgba(58, 60, 63, 1);
  height: auto;
  text-align: left;
  line-height: 24px;
}
 
.home-frame4815973 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
 
.home-frame-button15 {
  gap: 8px;
  cursor: pointer;
  display: flex;
  padding: 8px 16px;
  box-shadow: 0px 2px 4px 0px rgba(22, 23, 24, 0.23999999463558197) ;
  transition: 0.3s;
  align-items: center;
  border-color: rgba(58, 60, 63, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 96px;
  justify-content: center;
  text-decoration: none;
  background-color: rgba(22, 23, 24, 1);
}
 
.home-frame-button15:hover {
  background-color: #2b2b2d;
}
 
.home-text133 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  text-align: center;
  line-height: 24px;
  white-space: nowrap;
}
 
.home-frame-button16 {
  gap: 8px;
  cursor: pointer;
  display: flex;
  padding: 8px 16px;
  background: linear-gradient(180deg, rgba(137, 80, 250, 1) 0%, rgba(129, 53, 242, 1) 100%);
  box-shadow: 0px 2px 4px 0px rgba(48, 14, 103, 0.23999999463558197) ;
  transition: 0.3s;
  align-items: center;
  border-color: rgba(137, 80, 250, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 96px;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-gradient-primary);
}
 
.home-frame-button16:hover {
  background-color: #601ed4;
}
 
.home-text134 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  text-align: center;
  line-height: 24px;
  white-space: nowrap;
}
 
.home-right-icon4 {
  display: flex;
  align-items: center;
}
 
.home-arrow-right4 {
  width: 24px;
  height: 24px;
}
 
.home-image2 {
  width: 1200px;
  object-fit: cover;
}
 
.home-enterprise-cards2 {
  gap: 24px;
  width: 1229px;
  height: auto;
  display: flex;
  margin-top: -48px;
  align-items: center;
  flex-shrink: 0;
  margin-bottom: 0px;
}
 
.home-text135 {
  display: inline-block;
}
 
.home-text136 {
  display: inline-block;
}
 
.home-text137 {
  display: inline-block;
}
 
.home-text138 {
  display: inline-block;
}
 
.home-text139 {
  display: inline-block;
}
 
.home-text140 {
  display: inline-block;
}
 
.home-native-ecosystem {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.home-container4 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
}
 
.home-frame-typography14 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-text141 {
  color: rgba(22, 23, 24, 1);
  height: auto;
  align-self: stretch;
  text-align: center;
  line-height: 64px;
}
 
.home-frame-typography15 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-text145 {
  color: rgba(58, 60, 63, 1);
  height: auto;
  align-self: stretch;
  text-align: center;
  line-height: 24px;
}
 
.home-frame1171275357 {
  gap: 48px;
  width: 1200px;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-frame1171275349 {
  gap: 56px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
 
.home-frame1171275302 {
  width: 572px;
  height: 484px;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(229, 231, 232, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: 24px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-frame1171275347 {
  gap: 16px;
  display: flex;
  padding: 32px 0 0;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.home-frame-typography16 {
  width: 332px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-text149 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  align-self: stretch;
  text-align: center;
  line-height: 40px;
}
 
.home-frame-typography17 {
  width: 332px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-text150 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  align-self: stretch;
  text-align: center;
  line-height: 24px;
}
 
.home-frame4815974 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
 
.home-frame-button17 {
  gap: 8px;
  display: flex;
  padding: 8px 16px;
  background: linear-gradient(180deg, rgba(137, 80, 250, 1) 0%, rgba(129, 53, 242, 1) 100%);
  box-shadow: 0px 2px 4px 0px rgba(48, 14, 103, 0.23999999463558197) ;
  align-items: center;
  border-color: rgba(137, 80, 250, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 96px;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-gradient-primary);
}
 
.home-text154 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  text-align: center;
  line-height: 24px;
}
 
.home-right-icon5 {
  display: flex;
  align-items: center;
}
 
.home-arrow-right5 {
  width: 24px;
  height: 24px;
}
 
.home-frame-button18 {
  gap: 8px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  border-color: rgba(229, 231, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 96px;
  justify-content: center;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-text155 {
  color: rgba(22, 23, 24, 1);
  height: auto;
  text-align: center;
  line-height: 24px;
}
 
.home-image3 {
  width: auto;
  height: 481px;
  align-self: stretch;
}
 
.home-frame1171275333 {
  gap: 29px;
  width: 572px;
  height: 484px;
  display: flex;
  padding: 0 66px;
  overflow: hidden;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(229, 231, 232, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: 24px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-frame1171275351 {
  gap: 14px;
  display: flex;
  padding: 32px 0 0;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.home-frame-typography18 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.home-text156 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  align-self: stretch;
  text-align: center;
  line-height: 40px;
}
 
.home-frame-typography19 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.home-text157 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  align-self: stretch;
  text-align: center;
  line-height: 24px;
}
 
.home-frame4815975 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
 
.home-frame-button19 {
  gap: 8px;
  display: flex;
  padding: 8px 16px;
  background: linear-gradient(180deg, rgba(137, 80, 250, 1) 0%, rgba(129, 53, 242, 1) 100%);
  box-shadow: 0px 2px 4px 0px rgba(48, 14, 103, 0.23999999463558197) ;
  align-items: center;
  border-color: rgba(137, 80, 250, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 96px;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-gradient-primary);
}
 
.home-text161 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  text-align: center;
  line-height: 24px;
}
 
.home-right-icon6 {
  display: flex;
  align-items: center;
}
 
.home-arrow-right6 {
  width: 24px;
  height: 24px;
}
 
.home-frame-button20 {
  gap: 8px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  border-color: rgba(229, 231, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 96px;
  justify-content: center;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-text162 {
  color: rgba(22, 23, 24, 1);
  height: auto;
  text-align: center;
  line-height: 24px;
}
 
.home-frame11712753361 {
  width: 440px;
  height: 375px;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
}
 
.home-image4 {
  width: 100%;
  object-fit: cover;
}
 
.home-frame1171275352 {
  gap: 56px;
  display: none;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
 
.home-frame1171275332 {
  gap: 56px;
  width: 572px;
  height: 484px;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(229, 231, 232, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: 24px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-frame1171275348 {
  gap: 16px;
  display: flex;
  padding: 32px 0 0;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.home-frame-typography20 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.home-text163 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  align-self: stretch;
  text-align: center;
  line-height: 40px;
}
 
.home-frame-typography21 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.home-text164 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  align-self: stretch;
  text-align: center;
  line-height: 24px;
}
 
.home-frame4815976 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
 
.home-frame-button21 {
  gap: 8px;
  display: flex;
  padding: 8px 16px;
  background: linear-gradient(180deg, rgba(137, 80, 250, 1) 0%, rgba(129, 53, 242, 1) 100%);
  box-shadow: 0px 2px 4px 0px rgba(48, 14, 103, 0.23999999463558197) ;
  align-items: center;
  border-color: rgba(137, 80, 250, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 96px;
  justify-content: center;
  background-color: var(--dl-color-gradient-primary);
}
 
.home-text168 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  text-align: center;
  line-height: 24px;
}
 
.home-right-icon7 {
  display: flex;
  align-items: center;
}
 
.home-arrow-right7 {
  width: 24px;
  height: 24px;
}
 
.home-frame-button22 {
  gap: 8px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  border-color: rgba(229, 231, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 96px;
  justify-content: center;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-left-icon4 {
  display: flex;
  align-items: center;
}
 
.home-calendar2 {
  width: 24px;
  height: 24px;
}
 
.home-text169 {
  color: rgba(22, 23, 24, 1);
  height: auto;
  text-align: center;
  line-height: 24px;
}
 
.home-group481567 {
  width: 505px;
  height: 612.6489868164062px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-image5 {
  width: 100%;
  object-fit: cover;
}
 
.home-frame1171275334 {
  gap: 65px;
  width: 572px;
  height: 484px;
  display: flex;
  padding: 0 58px;
  overflow: hidden;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(229, 231, 232, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: 24px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-frame1171275350 {
  gap: 14px;
  display: flex;
  padding: 32px 0 0;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.home-frame-typography22 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.home-text170 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  align-self: stretch;
  text-align: center;
  line-height: 40px;
}
 
.home-frame-typography23 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.home-text171 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  align-self: stretch;
  text-align: center;
  line-height: 24px;
}
 
.home-frame4815977 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
 
.home-frame-button23 {
  gap: 8px;
  display: flex;
  padding: 8px 16px;
  background: linear-gradient(180deg, rgba(137, 80, 250, 1) 0%, rgba(129, 53, 242, 1) 100%);
  box-shadow: 0px 2px 4px 0px rgba(48, 14, 103, 0.23999999463558197) ;
  align-items: center;
  border-color: rgba(137, 80, 250, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 96px;
  justify-content: center;
  background-color: var(--dl-color-gradient-primary);
}
 
.home-text175 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  text-align: center;
  line-height: 24px;
}
 
.home-right-icon8 {
  display: flex;
  align-items: center;
}
 
.home-arrow-right8 {
  width: 24px;
  height: 24px;
}
 
.home-frame-button24 {
  gap: 8px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  border-color: rgba(229, 231, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 96px;
  justify-content: center;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-left-icon5 {
  display: flex;
  align-items: center;
}
 
.home-calendar3 {
  width: 24px;
  height: 24px;
}
 
.home-text176 {
  color: rgba(22, 23, 24, 1);
  height: auto;
  text-align: center;
  line-height: 24px;
}
 
.home-frame11712753351 {
  width: 456px;
  height: 456px;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
}
 
.home-image6 {
  width: 100%;
  object-fit: cover;
}
 
.home-frame1171275338 {
  gap: 16px;
  width: 1220px;
  height: auto;
  display: flex;
  padding: 34px 64px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(229, 231, 232, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: 24px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-frame-typography24 {
  width: 332px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-text177 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  align-self: stretch;
  text-align: center;
  line-height: 40px;
}
 
.home-frame-typography25 {
  width: 332px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-text178 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  align-self: stretch;
  text-align: center;
  line-height: 24px;
}
 
.home-frame4815978 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
 
.home-frame-button25 {
  gap: 8px;
  display: flex;
  padding: 8px 16px;
  background: linear-gradient(180deg, rgba(137, 80, 250, 1) 0%, rgba(129, 53, 242, 1) 100%);
  box-shadow: 0px 2px 4px 0px rgba(48, 14, 103, 0.23999999463558197) ;
  transition: 0.3s;
  align-items: center;
  border-color: rgba(137, 80, 250, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 96px;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-gradient-primary);
}
 
.home-frame-button25:hover {
  cursor: pointer;
  background-color: #601ed4;
}
 
.home-text182 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  text-align: center;
  line-height: 24px;
  white-space: nowrap;
}
 
.home-right-icon9 {
  display: flex;
  align-items: center;
}
 
.home-arrow-right9 {
  width: 24px;
  height: 24px;
}
 
.home-frame-button26 {
  gap: 8px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  border-color: rgba(229, 231, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 96px;
  justify-content: center;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-left-icon6 {
  display: flex;
  align-items: center;
}
 
.home-calendar4 {
  width: 24px;
  height: 24px;
}
 
.home-text183 {
  color: rgba(22, 23, 24, 1);
  height: auto;
  text-align: center;
  line-height: 24px;
}
 
.home-frame2 {
  gap: 16px;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.home-logogoldsky {
  width: 48px;
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.home-clippathgroup3 {
  top: 0px;
  left: 0px;
  width: 48px;
  height: 48px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-clip025012199 {
  top: 0px;
  left: 0px;
  width: 48px;
  height: 48px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector120 {
  top: 0px;
  left: 0px;
  width: 48px;
  height: 48px;
  position: absolute;
}
 
.home-group28 {
  top: -5.25px;
  left: -6px;
  width: 59.25px;
  height: 59.25px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector121 {
  top: 5.25px;
  left: 6px;
  width: 48px;
  height: 48px;
  position: absolute;
}
 
.home-maskgroup1 {
  top: 0px;
  left: 0px;
  width: 59.25px;
  height: 59.25px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-group29 {
  top: 0px;
  left: 0px;
  width: 59.25px;
  height: 59.25px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector122 {
  top: 0px;
  left: 0px;
  width: 59px;
  height: 59px;
  position: absolute;
}
 
.home-group30 {
  top: 0px;
  left: 0px;
  width: 59.25px;
  height: 59.25px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector123 {
  top: 0px;
  left: 0px;
  width: 59px;
  height: 59px;
  position: absolute;
}
 
.home-group31 {
  top: 12.34375px;
  left: 12.34375px;
  width: 34.56245040893555px;
  height: 17.28125px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector124 {
  top: 0px;
  left: 0px;
  width: 35px;
  height: 17px;
  position: absolute;
}
 
.home-vector125 {
  top: 29.625px;
  left: 12.34375px;
  width: 35px;
  height: 17px;
  position: absolute;
}
 
.home-group32 {
  top: 0px;
  left: 0px;
  width: 59.25px;
  height: 29.625px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector126 {
  top: 0px;
  left: 0px;
  width: 59px;
  height: 30px;
  position: absolute;
}
 
.home-logothegraph {
  width: 48px;
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 6px;
  background-color: rgba(13, 11, 32, 1);
}
 
.home-symbols {
  top: 11.099990844726562px;
  left: 14.050048828125px;
  width: 22.450000762939453px;
  height: 28.83749771118164px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-group33 {
  top: 0px;
  left: 0px;
  width: 22.450000762939453px;
  height: 28.83749771118164px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-fill19 {
  top: 0px;
  left: 0px;
  width: 22px;
  height: 29px;
  position: absolute;
}
 
.home-logoblockscout {
  width: 48px;
  height: 48px;
}
 
.home-logodora1 {
  width: 48px;
  height: 48px;
}
 
.home-logoredstone {
  width: 48px;
  height: 48px;
}
 
.home-logoapi3 {
  width: 48px;
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 6px;
}
 
.home-layerx002013 {
  top: 0px;
  left: 0px;
  width: 48px;
  height: 48px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-frame2806123167536 {
  top: 0px;
  left: 0px;
  width: 48px;
  height: 48px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector127 {
  top: 0px;
  left: 0px;
  width: 48px;
  height: 48px;
  position: absolute;
}
 
.home-group34 {
  top: 8.169600486755371px;
  left: 5.721526622772217px;
  width: 36.556941986083984px;
  height: 31.670398712158203px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector128 {
  top: 6.499199867248535px;
  left: 0.01920032501220703px;
  width: 14px;
  height: 25px;
  position: absolute;
}
 
.home-vector129 {
  top: 25.027198791503906px;
  left: 7.526472568511963px;
  width: 29px;
  height: 7px;
  position: absolute;
}
 
.home-vector130 {
  top: -9.5367431640625e-7px;
  left: 14.169672012329102px;
  width: 19px;
  height: 25px;
  position: absolute;
}
 
.home-vector131 {
  top: 11.836800575256348px;
  left: 7.526472568511963px;
  width: 5px;
  height: 20px;
  position: absolute;
}
 
.home-vector132 {
  top: 6.479999542236328px;
  left: 12.787273406982422px;
  width: 16px;
  height: 15px;
  position: absolute;
}
 
.home-vector133 {
  top: 21.167999267578125px;
  left: 12.787273406982422px;
  width: 20px;
  height: 9px;
  position: absolute;
}
 
.home-vector134 {
  top: 15.811200141906738px;
  left: 12.787273406982422px;
  width: 3px;
  height: 14px;
  position: absolute;
}
 
.home-vector135 {
  top: 19.171199798583984px;
  left: 16.24327278137207px;
  width: 13px;
  height: 9px;
  position: absolute;
}
 
.home-vector136 {
  top: 11.855999946594238px;
  left: 12.787273406982422px;
  width: 13px;
  height: 7px;
  position: absolute;
}
 
.home-vector137 {
  top: 15.811200141906738px;
  left: 12.787273406982422px;
  width: 10px;
  height: 3px;
  position: absolute;
}
 
.home-vector138 {
  top: 18.479999542236328px;
  left: 13.478471755981445px;
  width: 5px;
  height: 10px;
  position: absolute;
}
 
.home-vector139 {
  top: 18.479999542236328px;
  left: 18.220870971679688px;
  width: 7px;
  height: 8px;
  position: absolute;
}
 
.home-vector140 {
  top: 18.479999542236328px;
  left: 13.478471755981445px;
  width: 7px;
  height: 2px;
  position: absolute;
}
 
.home-vector141 {
  top: 20.26559829711914px;
  left: 14.55367374420166px;
  width: 5px;
  height: 6px;
  position: absolute;
}
 
.home-vector142 {
  top: 18.479999542236328px;
  left: 19.21927261352539px;
  width: 4px;
  height: 6px;
  position: absolute;
}
 
.home-vector143 {
  top: 6.479999542236328px;
  left: 0px;
  width: 14px;
  height: 25px;
  position: absolute;
}
 
.home-vector144 {
  top: 25.027198791503906px;
  left: 7.526472568511963px;
  width: 29px;
  height: 7px;
  position: absolute;
}
 
.home-vector145 {
  top: -9.5367431640625e-7px;
  left: 14.169672012329102px;
  width: 19px;
  height: 25px;
  position: absolute;
}
 
.home-vector146 {
  top: 11.836800575256348px;
  left: 7.526472568511963px;
  width: 5px;
  height: 20px;
  position: absolute;
}
 
.home-vector147 {
  top: 21.167999267578125px;
  left: 12.787273406982422px;
  width: 20px;
  height: 9px;
  position: absolute;
}
 
.home-vector148 {
  top: 15.811200141906738px;
  left: 12.787273406982422px;
  width: 3px;
  height: 14px;
  position: absolute;
}
 
.home-vector149 {
  top: 19.171199798583984px;
  left: 16.24327278137207px;
  width: 13px;
  height: 9px;
  position: absolute;
}
 
.home-vector150 {
  top: 18.479999542236328px;
  left: 13.478471755981445px;
  width: 5px;
  height: 10px;
  position: absolute;
}
 
.home-vector151 {
  top: 18.479999542236328px;
  left: 18.220870971679688px;
  width: 7px;
  height: 8px;
  position: absolute;
}
 
.home-vector152 {
  top: 20.265600204467773px;
  left: 14.55367374420166px;
  width: 5px;
  height: 6px;
  position: absolute;
}
 
.home-vector153 {
  top: 18.479999542236328px;
  left: 19.21927261352539px;
  width: 4px;
  height: 6px;
  position: absolute;
}
 
.home-logoaxelar {
  width: 48px;
  height: 48px;
}
 
.home-logoaws {
  width: 48px;
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-amazon-web-services-logo1 {
  top: 13.5px;
  left: 6.75px;
  width: 34px;
  height: 21px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.home-group35 {
  top: 0.1269230842590332px;
  left: 0.18929412961006165px;
  width: 34.1222038269043px;
  height: 20.746152877807617px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector154 {
  top: -2.7755575615628914e-17px;
  left: 1.4108704328536987px;
  width: 31px;
  height: 11px;
  position: absolute;
}
 
.home-group36 {
  top: 12.933721542358398px;
  left: 0px;
  width: 34.1222038269043px;
  height: 7.812430381774902px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector155 {
  top: 0.6866192817687988px;
  left: 0px;
  width: 31px;
  height: 7px;
  position: absolute;
}
 
.home-vector156 {
  top: 4.76837158203125e-7px;
  left: 27.726951599121094px;
  width: 6px;
  height: 6px;
  position: absolute;
}
 
.home-logogooglecloudgcp {
  width: 48px;
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-frame8 {
  top: 8.25px;
  left: 4.5px;
  width: 39px;
  height: 31px;
  position: absolute;
}
 
.home-logomicrosoftazure {
  width: 48px;
  height: 48px;
}
 
.home-logobaremetal {
  width: 48px;
  height: 48px;
}
 
.home-logobtqzkproofs {
  width: 48px;
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-maskgroup2 {
  top: 7.5px;
  left: 7.5px;
  width: 33px;
  height: 33px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-group37 {
  top: 0px;
  left: 0px;
  width: 33px;
  height: 33px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector157 {
  top: 0px;
  left: 0px;
  width: 33px;
  height: 33px;
  position: absolute;
}
 
.home-group38 {
  top: 0.000049591064453125px;
  left: -0.0037784576416015625px;
  width: 33.00000762939453px;
  height: 32.99996566772461px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector158 {
  top: 0px;
  left: 0px;
  width: 33px;
  height: 33px;
  position: absolute;
}
 
.home-vector159 {
  top: 1.4061479568481445px;
  left: 2.0636816024780273px;
  width: 29px;
  height: 30px;
  position: absolute;
}
 
.home-vector160 {
  top: 0.9315481185913086px;
  left: 1.2811546325683594px;
  width: 30px;
  height: 31px;
  position: absolute;
}
 
.home-logogevulot {
  width: 48px;
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-gevulotgcolor1 {
  top: 10.5px;
  left: 10.5px;
  width: 27px;
  height: 27px;
  position: absolute;
}
 
.home-logonear {
  width: 48px;
  height: 48px;
}
 
.home-logocelestia {
  width: 48px;
  height: 48px;
}
 
.home-logoavail {
  width: 48px;
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-avail1 {
  top: 6px;
  left: 6px;
  width: 36px;
  height: 36px;
  position: absolute;
}
 
.home-logomagiclink {
  width: 48px;
  height: 48px;
}
 
.home-logometakeep {
  width: 48px;
  height: 48px;
}
 
.home-logometamask {
  width: 48px;
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-frame481577 {
  top: 9px;
  left: 7.5px;
  width: 32px;
  height: 30px;
  position: absolute;
}
 
.home-logohardhat {
  width: 48px;
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-hardhatseeklogocom1 {
  top: 11.25px;
  left: 6px;
  width: 36px;
  height: 25.5px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.home-group39 {
  top: 0px;
  left: 0px;
  width: 36px;
  height: 25.49663543701172px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector161 {
  top: 19.94839096069336px;
  left: 0.0039706421084702015px;
  width: 36px;
  height: 6px;
  position: absolute;
}
 
.home-vector162 {
  top: 2.127070188522339px;
  left: 1.5066585540771484px;
  width: 11px;
  height: 18px;
  position: absolute;
}
 
.home-vector163 {
  top: 7.3027448654174805px;
  left: 30.730754852294922px;
  width: 4px;
  height: 13px;
  position: absolute;
}
 
.home-vector164 {
  top: 7.3027448654174805px;
  left: 30.730754852294922px;
  width: 4px;
  height: 13px;
  position: absolute;
}
 
.home-vector165 {
  top: 1.1504260301589966px;
  left: 8.628093719482422px;
  width: 24px;
  height: 19px;
  position: absolute;
}
 
.home-group40 {
  top: 0px;
  left: 11.446125030517578px;
  width: 13.190437316894531px;
  height: 8.419651985168457px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector166 {
  top: 0px;
  left: 0px;
  width: 13px;
  height: 8px;
  position: absolute;
}
 
.home-vector167 {
  top: 18.903257369995117px;
  left: 5.198051988841712e-10px;
  width: 36px;
  height: 5px;
  position: absolute;
}
 
.home-vector168 {
  top: 13.534037590026855px;
  left: 17.998441696166992px;
  width: 4px;
  height: 6px;
  position: absolute;
}
 
.home-vector169 {
  top: 13.534037590026855px;
  left: 14.206693649291992px;
  width: 4px;
  height: 6px;
  position: absolute;
}
 
.home-vector170 {
  top: 5.824557304382324px;
  left: 14.206693649291992px;
  width: 4px;
  height: 9px;
  position: absolute;
}
 
.home-vector171 {
  top: 5.8237504959106445px;
  left: 17.998441696166992px;
  width: 4px;
  height: 9px;
  position: absolute;
}
 
.home-logofractalid {
  width: 48px;
  height: 48px;
}
 
.home-logogatenox {
  width: 48px;
  height: 48px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.home-z50v-l-hlz400x40021 {
  top: 0px;
  left: 0px;
  width: 48px;
  height: 48px;
  position: absolute;
  border-radius: 6px;
}
 
.home-logopolygon2 {
  width: 48px;
  height: 48px;
}
 
.home-logoethereum {
  width: 48px;
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-clippathgroup4 {
  top: 6.899999618530273px;
  left: 13.5px;
  width: 21px;
  height: 34.20703125px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-clip0194206 {
  top: 0px;
  left: 0px;
  width: 21px;
  height: 34.20703125px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector172 {
  top: 0px;
  left: 0px;
  width: 21px;
  height: 34px;
  position: absolute;
}
 
.home-group41 {
  top: 0px;
  left: 0px;
  width: 20.999923706054688px;
  height: 34.19938659667969px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector173 {
  top: 0.000013828277587890625px;
  left: 10.267560958862305px;
  width: 11px;
  height: 24px;
  position: absolute;
}
 
.home-vector174 {
  top: 0px;
  left: 0px;
  width: 10px;
  height: 24px;
  position: absolute;
}
 
.home-vector175 {
  top: 19.40749168395996px;
  left: 10.367635726928711px;
  width: 11px;
  height: 15px;
  position: absolute;
}
 
.home-vector176 {
  top: 19.40736961364746px;
  left: 0px;
  width: 10px;
  height: 15px;
  position: absolute;
}
 
.home-vector177 {
  top: 12.645818710327148px;
  left: 10.496795654296875px;
  width: 10px;
  height: 11px;
  position: absolute;
}
 
.home-vector178 {
  top: 12.645818710327148px;
  left: 0.000164031982421875px;
  width: 10px;
  height: 11px;
  position: absolute;
}
 
.home-logooptimism2 {
  width: 48px;
  height: 48px;
}
 
.home-logolinea2 {
  width: 48px;
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 6px;
  background-color: rgba(18, 18, 18, 1);
}
 
.home-linea13 {
  top: 10.5px;
  left: 12px;
  width: 25px;
  height: 27px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.home-clippathgroup5 {
  top: 0.44162464141845703px;
  left: 0px;
  width: 24.7020206451416px;
  height: 25.893508911132812px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-clip016424703 {
  top: 0px;
  left: 0px;
  width: 24.7020206451416px;
  height: 25.893508911132812px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector179 {
  top: 0px;
  left: 0px;
  width: 25px;
  height: 26px;
  position: absolute;
}
 
.home-group42 {
  top: 0.000030994415283203125px;
  left: 0px;
  width: 24.7020206451416px;
  height: 25.893558502197266px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector180 {
  top: 4.201694965362549px;
  left: 0px;
  width: 21px;
  height: 22px;
  position: absolute;
}
 
.home-vector181 {
  top: -0.000004827976226806641px;
  left: 16.328073501586914px;
  width: 8px;
  height: 8px;
  position: absolute;
}
 
.home-logozkevm {
  width: 48px;
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-frame481579 {
  top: 4.5px;
  left: 6px;
  width: 35px;
  height: 39px;
  position: absolute;
}
 
.home-logohalliday {
  width: 48px;
  height: 48px;
}
 
.home-logo3commas {
  width: 48px;
  height: 48px;
}
 
.home-logosimplehash {
  width: 48px;
  height: 48px;
}
 
.home-logosafe {
  width: 48px;
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-frame481581 {
  top: 7.5px;
  left: 7.5px;
  width: 32px;
  height: 33px;
  position: absolute;
}
 
.home-logobiconomy {
  width: 48px;
  height: 48px;
}
 
.home-logosub7 {
  width: 48px;
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-maskgroup3 {
  top: 5.25px;
  left: 8.25px;
  width: 31.5px;
  height: 37.5px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-q-ixc-oq-ye-rmw-c85-rhv-bu4y-yhyou1 {
  top: 0px;
  left: 0px;
  width: 31px;
  height: 37px;
  position: absolute;
}
 
.home-rectangle3690 {
  top: 0px;
  left: 1.260000228881836px;
  width: 29px;
  height: 37px;
  position: absolute;
}
 
.home-logodomicon {
  width: 48px;
  height: 48px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.home-domicon1 {
  top: 0px;
  left: 0px;
  width: 48px;
  height: 48px;
  position: absolute;
  border-radius: 6px;
}
 
.home-logogateway {
  width: 48px;
  height: 48px;
}
 
.home-logoamlbot {
  width: 48px;
  height: 48px;
}
 
.home-logoden {
  width: 48px;
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-maskgroup4 {
  top: 6px;
  left: 13.5px;
  width: 21.161739349365234px;
  height: 36px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
  background-color: rgba(217, 217, 217, 1);
}
 
.home-den1 {
  top: -10.267822265625px;
  left: -19.65915298461914px;
  width: 131px;
  height: 56px;
  position: absolute;
}
 
.home-logoquantstamp {
  width: 48px;
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 6px;
  background-color: rgba(7, 19, 55, 1);
}
 
.home-group2465 {
  top: 9px;
  left: 9px;
  width: 30px;
  height: 30.130958557128906px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector12 {
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  position: absolute;
}
 
.home-logooxorio {
  width: 48px;
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 6px;
  background-color: rgba(32, 32, 32, 1);
}
 
.home-frame481583 {
  top: 6px;
  left: 7.5px;
  width: 32px;
  height: 36px;
  position: absolute;
}
 
.home-logoshieldifysecurity {
  width: 48px;
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-layer12 {
  top: 7.5px;
  left: 7.5px;
  width: 33.00002670288086px;
  height: 33px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector182 {
  top: 1.269230842590332px;
  left: 1.269230842590332px;
  width: 30px;
  height: 30px;
  position: absolute;
}
 
.home-vector-stroke1 {
  top: 0px;
  left: 0.000019788742065429688px;
  width: 33px;
  height: 33px;
  position: absolute;
}
 
.home-vector183 {
  top: 1.2692699432373047px;
  left: 1.269230842590332px;
  width: 28px;
  height: 28px;
  position: absolute;
}
 
.home-vector-stroke2 {
  top: 0.000031948089599609375px;
  left: 0px;
  width: 30px;
  height: 30px;
  position: absolute;
}
 
.home-vector184 {
  top: 8.3809232711792px;
  left: 8.381542205810547px;
  width: 13px;
  height: 13px;
  position: absolute;
}
 
.home-vector185 {
  top: 13.741729736328125px;
  left: 13.742929458618164px;
  width: 3px;
  height: 3px;
  position: absolute;
}
 
.home-logoradius {
  width: 48px;
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-layer11 {
  top: 6px;
  left: 6px;
  width: 35.88911437988281px;
  height: 36px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-group43 {
  top: 0px;
  left: 0px;
  width: 35.88911437988281px;
  height: 36px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector186 {
  top: 9.316040992736816px;
  left: 0px;
  width: 22px;
  height: 19px;
  position: absolute;
}
 
.home-vector21 {
  top: 7.349451065063477px;
  left: 13.992477416992188px;
  width: 22px;
  height: 19px;
  position: absolute;
}
 
.home-vector3 {
  top: 0px;
  left: 7.329235076904297px;
  width: 19px;
  height: 22px;
  position: absolute;
}
 
.home-vector4 {
  top: 14.034500122070312px;
  left: 9.288656234741211px;
  width: 19px;
  height: 22px;
  position: absolute;
}
 
.home-logohorizon {
  width: 48px;
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 1);
}
 
.home-frame481584 {
  top: 10.5px;
  left: 6px;
  width: 36px;
  height: 29px;
  position: absolute;
}
 
.home-logostellar {
  width: 48px;
  height: 48px;
}
 
.home-logopolygoncdk {
  width: 48px;
  height: 48px;
}
 
.home-logozksync {
  width: 48px;
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.home-group2466 {
  top: 11.25px;
  left: 0.75px;
  width: 46.5px;
  height: 25.5px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector187 {
  top: 0px;
  left: 21px;
  width: 25px;
  height: 25px;
  position: absolute;
}
 
.home-vector188 {
  top: 0px;
  left: 0px;
  width: 25px;
  height: 25px;
  position: absolute;
}
 
.home-logoimmutable {
  width: 48px;
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.home-svg646557ee455c3e16e4a9bf82zk-ev-micon1 {
  top: 6.75px;
  left: 6.75px;
  width: 34px;
  height: 37px;
  position: absolute;
}
 
.home-logoarbitrum2 {
  width: 48px;
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.home-arbitrumarblogo13 {
  top: 1.5px;
  left: 1.5px;
  width: 45px;
  height: 45px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.home-layerx002014 {
  top: 0px;
  left: 0px;
  width: 45px;
  height: 45px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-frame24055884772323 {
  top: 0px;
  left: 0px;
  width: 45px;
  height: 45px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-group44 {
  top: 0px;
  left: 2.555999755859375px;
  width: 39.88800048828125px;
  height: 44.96399688720703px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-group45 {
  top: 0px;
  left: 0px;
  width: 39.88800048828125px;
  height: 44.96399688720703px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector189 {
  top: 1.7054998874664307px;
  left: 1.5120000839233398px;
  width: 37px;
  height: 42px;
  position: absolute;
}
 
.home-group46 {
  top: 15.632999420166016px;
  left: 21.055500030517578px;
  width: 14.188499450683594px;
  height: 27.06299591064453px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-group47 {
  top: 0px;
  left: 0px;
  width: 14.188499450683594px;
  height: 27.06299591064453px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-group48 {
  top: 10.044000625610352px;
  left: 0px;
  width: 8.122501373291016px;
  height: 17.018999099731445px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector190 {
  top: 0px;
  left: 0px;
  width: 8px;
  height: 17px;
  position: absolute;
}
 
.home-group49 {
  top: 9.5367431640625e-7px;
  left: 3.6720008850097656px;
  width: 10.516498565673828px;
  height: 23.571002960205078px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector191 {
  top: 0px;
  left: 0px;
  width: 11px;
  height: 24px;
  position: absolute;
}
 
.home-group50 {
  top: 0px;
  left: 0px;
  width: 39.88800048828125px;
  height: 44.96399688720703px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector192 {
  top: 0px;
  left: 0px;
  width: 40px;
  height: 45px;
  position: absolute;
}
 
.home-vector193 {
  top: 35.045997619628906px;
  left: 9.000000953674316px;
  width: 5px;
  height: 5px;
  position: absolute;
}
 
.home-group51 {
  top: 11.574000358581543px;
  left: 4.66200065612793px;
  width: 21.587512969970703px;
  height: 31.12200164794922px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector194 {
  top: 0px;
  left: 0px;
  width: 14px;
  height: 28px;
  position: absolute;
}
 
.home-vector195 {
  top: 0px;
  left: 6.0659990310668945px;
  width: 16px;
  height: 31px;
  position: absolute;
}
 
.home-logohorizen1 {
  width: 47.99364471435547px;
  height: 48px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.home-layer21 {
  top: 6px;
  left: 6px;
  width: 36.000396728515625px;
  height: 36.005165100097656px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-layer13 {
  top: 0px;
  left: 0px;
  width: 36.000396728515625px;
  height: 36.005165100097656px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector196 {
  top: 7.537348747253418px;
  left: 7.554958343505859px;
  width: 28px;
  height: 28px;
  position: absolute;
}
 
.home-vector197 {
  top: 19.150100708007812px;
  left: 10.516229629516602px;
  width: 16px;
  height: 8px;
  position: absolute;
}
 
.home-vector198 {
  top: 0px;
  left: 0px;
  width: 28px;
  height: 29px;
  position: absolute;
}
 
.home-logosindri {
  width: 48px;
  height: 48px;
}
 
.home-logolayerzero {
  width: 48px;
  height: 48px;
}
 
.home-logoeigenlayer {
  width: 48px;
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.home-frame481585 {
  top: 8.25px;
  left: 10.50640869140625px;
  width: 27px;
  height: 31.5px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.home-vector1 {
  top: 0px;
  left: 0px;
  width: 23px;
  height: 31px;
  position: absolute;
}
 
.home-vector22 {
  top: 0px;
  left: 11.748502731323242px;
  width: 11px;
  height: 16px;
  position: absolute;
}
 
.home-rectangle3692 {
  top: 0px;
  left: 23.119762420654297px;
  width: 4px;
  height: 8px;
  position: absolute;
}
 
.home-mentions {
  gap: 32px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.home-frame-typography26 {
  height: auto;
  display: flex;
  max-width: 1200px;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-text184 {
  color: rgba(22, 23, 24, 1);
  height: auto;
  align-self: stretch;
  text-align: center;
  line-height: 64px;
}
 
.home-tweets {
  gap: 16px;
  width: 1218px;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.home-frame1171275353 {
  gap: 16px;
  width: 395.3333435058594px;
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-frame1171275327 {
  gap: 12px;
  display: flex;
  padding: 24px;
  overflow: hidden;
  align-self: stretch;
  box-shadow: 0px 2px 4px 0px rgba(22, 23, 24, 0.07999999821186066) ;
  align-items: flex-start;
  border-radius: 12px;
  flex-direction: column;
  background-color: rgba(245, 243, 255, 1);
}
 
.home-frame117127533710 {
  gap: 16px;
  display: flex;
  align-items: center;
}
 
.home-logopolygon3 {
  width: 32px;
  height: 32px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.home-logopolygon4 {
  top: 0px;
  left: 0px;
  width: 32px;
  height: 32px;
  position: absolute;
}
 
.home-frame-typography27 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-text185 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 40px;
}
 
.home-frame-typography28 {
  width: 333.66668701171875px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-text186 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 24px;
}
 
.home-frame11712753362 {
  gap: 12px;
  display: flex;
  padding: 24px;
  overflow: hidden;
  align-self: stretch;
  box-shadow: 0px 2px 4px 0px rgba(22, 23, 24, 0.07999999821186066) ;
  align-items: flex-start;
  border-radius: 12px;
  flex-direction: column;
  background-color: rgba(245, 243, 255, 1);
}
 
.home-frame117127533711 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
 
.home-gpt {
  width: 29px;
  height: 30px;
}
 
.home-frame-typography29 {
  width: 302.0547180175781px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-text187 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 40px;
}
 
.home-frame-typography30 {
  width: 333.66668701171875px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-text188 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 24px;
}
 
.home-frame1171275339 {
  gap: 12px;
  display: flex;
  padding: 24px;
  overflow: hidden;
  align-self: stretch;
  box-shadow: 0px 2px 4px 0px rgba(22, 23, 24, 0.07999999821186066) ;
  align-items: flex-start;
  border-radius: 12px;
  flex-direction: column;
  background-color: rgba(245, 243, 255, 1);
}
 
.home-frame117127533712 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
 
.home-logodora2 {
  width: 32px;
  height: 32px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.home-logodora3 {
  top: 0px;
  left: 0px;
  width: 32px;
  height: 32px;
  position: absolute;
}
 
.home-frame-typography31 {
  width: 299.3333435058594px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-text189 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 40px;
}
 
.home-frame-typography32 {
  width: 333.66668701171875px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-text190 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 24px;
}
 
.home-frame1171275354 {
  gap: 16px;
  width: 395.33331298828125px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-frame11712753352 {
  gap: 12px;
  display: flex;
  padding: 24px;
  overflow: hidden;
  align-self: stretch;
  box-shadow: 0px 2px 4px 0px rgba(22, 23, 24, 0.07999999821186066) ;
  align-items: flex-start;
  border-radius: 12px;
  flex-direction: column;
  background-color: rgba(245, 243, 255, 1);
}
 
.home-frame117127533713 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
 
.home-xwcahujp400x4001 {
  width: 40px;
  height: 40px;
  border-radius: 30px;
}
 
.home-frame-typography33 {
  width: 291.33331298828125px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-text191 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 40px;
}
 
.home-frame-typography34 {
  width: 333.66668701171875px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-text192 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 24px;
}
 
.home-frame1171275340 {
  gap: 12px;
  display: flex;
  padding: 24px;
  overflow: hidden;
  align-self: stretch;
  box-shadow: 0px 2px 4px 0px rgba(22, 23, 24, 0.07999999821186066) ;
  align-items: flex-start;
  border-radius: 12px;
  flex-direction: column;
  background-color: rgba(245, 243, 255, 1);
}
 
.home-frame117127533714 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
 
.home-logognosis1 {
  width: 32px;
  height: 32px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.home-logognosis2 {
  gap: 10px;
  top: 0px;
  left: 0px;
  width: 32px;
  height: 32px;
  display: flex;
  padding: 5px;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
}
 
.home-gnosisgnognologo1 {
  top: 5px;
  left: 5px;
  width: 22px;
  height: 22px;
  position: absolute;
}
 
.home-frame-typography35 {
  width: 299.33331298828125px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-text196 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 40px;
}
 
.home-frame-typography36 {
  width: 333.66668701171875px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-text197 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 24px;
}
 
.home-frame1171275343 {
  gap: 12px;
  display: flex;
  padding: 24px;
  overflow: hidden;
  align-self: stretch;
  box-shadow: 0px 2px 4px 0px rgba(22, 23, 24, 0.07999999821186066) ;
  align-items: flex-start;
  border-radius: 12px;
  flex-direction: column;
  background-color: rgba(245, 243, 255, 1);
}
 
.home-frame117127533715 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
 
.home-frame1171275344 {
  width: 20px;
  height: 20px;
}
 
.home-frame-typography37 {
  width: 311.4438781738281px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-text198 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 40px;
}
 
.home-frame-typography38 {
  width: 333.66668701171875px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-text199 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 24px;
}
 
.home-frame1171275355 {
  gap: 16px;
  width: 395.33331298828125px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-frame117127533716 {
  gap: 12px;
  display: flex;
  padding: 24px;
  overflow: hidden;
  align-self: stretch;
  box-shadow: 0px 2px 4px 0px rgba(22, 23, 24, 0.07999999821186066) ;
  align-items: flex-start;
  border-radius: 12px;
  flex-direction: column;
  background-color: rgba(245, 243, 255, 1);
}
 
.home-frame117127533717 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
 
.home-vector199 {
  width: 39px;
  height: 23px;
}
 
.home-frame-typography39 {
  width: 292.6833190917969px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-text200 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 40px;
}
 
.home-frame-typography40 {
  width: 333.66668701171875px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-text201 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 24px;
}
 
.home-frame1171275341 {
  gap: 12px;
  display: flex;
  padding: 24px;
  overflow: hidden;
  align-self: stretch;
  box-shadow: 0px 2px 4px 0px rgba(22, 23, 24, 0.07999999821186066) ;
  align-items: flex-start;
  border-radius: 12px;
  flex-direction: column;
  background-color: rgba(245, 243, 255, 1);
}
 
.home-frame117127533718 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
 
.home-logoarbitrum3 {
  width: 32px;
  height: 32px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.home-logoarbitrum4 {
  top: 0px;
  left: 0px;
  width: 32px;
  height: 32px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.home-arbitrumarblogo14 {
  top: 1px;
  left: 1px;
  width: 30px;
  height: 30px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.home-layerx002015 {
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-frame24055884772324 {
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-group52 {
  top: 0px;
  left: 1.7039998769760132px;
  width: 26.591999053955078px;
  height: 29.975997924804688px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-group53 {
  top: 0px;
  left: 0px;
  width: 26.591999053955078px;
  height: 29.975997924804688px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector200 {
  top: 1.1369999647140503px;
  left: 1.0080000162124634px;
  width: 25px;
  height: 28px;
  position: absolute;
}
 
.home-group54 {
  top: 10.42199993133545px;
  left: 14.036999702453613px;
  width: 9.458999633789062px;
  height: 18.0419979095459px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-group55 {
  top: 0px;
  left: 0px;
  width: 9.458999633789062px;
  height: 18.0419979095459px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-group56 {
  top: 6.695999622344971px;
  left: 4.205703305615316e-7px;
  width: 5.4150004386901855px;
  height: 11.346000671386719px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector201 {
  top: 0px;
  left: 0px;
  width: 5px;
  height: 11px;
  position: absolute;
}
 
.home-group57 {
  top: 6.866456203624693e-8px;
  left: 2.448000431060791px;
  width: 7.01099967956543px;
  height: 15.714000701904297px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector202 {
  top: 0px;
  left: 0px;
  width: 7px;
  height: 16px;
  position: absolute;
}
 
.home-group58 {
  top: 0px;
  left: 0px;
  width: 26.591999053955078px;
  height: 29.975997924804688px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector203 {
  top: 0px;
  left: 0px;
  width: 27px;
  height: 30px;
  position: absolute;
}
 
.home-vector204 {
  top: 23.363998413085938px;
  left: 6.000000476837158px;
  width: 3px;
  height: 3px;
  position: absolute;
}
 
.home-group59 {
  top: 7.716000080108643px;
  left: 3.1080002784729004px;
  width: 14.39167594909668px;
  height: 20.748003005981445px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector205 {
  top: 0px;
  left: 0px;
  width: 10px;
  height: 18px;
  position: absolute;
}
 
.home-vector206 {
  top: -8.010863439267268e-8px;
  left: 4.043999671936035px;
  width: 10px;
  height: 21px;
  position: absolute;
}
 
.home-frame-typography41 {
  width: 299.33331298828125px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-text202 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 40px;
}
 
.home-frame-typography42 {
  width: 333.66668701171875px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-text203 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 24px;
}
 
.home-frame1171275342 {
  gap: 12px;
  display: flex;
  padding: 24px;
  overflow: hidden;
  align-self: stretch;
  box-shadow: 0px 2px 4px 0px rgba(22, 23, 24, 0.07999999821186066) ;
  align-items: flex-start;
  border-radius: 12px;
  flex-direction: column;
  background-color: rgba(245, 243, 255, 1);
}
 
.home-frame117127533719 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
 
.home-logohorizen2 {
  width: 31.995763778686523px;
  height: 32px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.home-logohorizen3 {
  top: 0px;
  left: 0px;
  width: 32px;
  height: 32px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.home-layer22 {
  top: 4px;
  left: 4px;
  width: 24.00026512145996px;
  height: 24.003442764282227px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-layer14 {
  top: 0px;
  left: 0px;
  width: 24.00026512145996px;
  height: 24.003442764282227px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
 
.home-vector207 {
  top: 5.024899005889893px;
  left: 5.036639213562012px;
  width: 19px;
  height: 19px;
  position: absolute;
}
 
.home-vector208 {
  top: 12.766733169555664px;
  left: 7.010819435119629px;
  width: 11px;
  height: 5px;
  position: absolute;
}
 
.home-vector209 {
  top: 0px;
  left: 0px;
  width: 19px;
  height: 19px;
  position: absolute;
}
 
.home-frame-typography43 {
  width: 299.3375549316406px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-text204 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 40px;
}
 
.home-frame-typography44 {
  width: 333.66668701171875px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-text205 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 24px;
}
 
@media(max-width: 550px) {
  .home-container1 {
    gap: var(--dl-space-space-twounits);
  }
  .home-hero-space {
    gap: var(--dl-space-space-threeunits);
    width: 100%;
    padding-top: 0px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-alert {
    border-radius: var(--dl-radius-radius-inputradius);
    flex-direction: column;
  }
  .home-container2 {
    gap: var(--dl-space-space-unit);
  }
  .home-container3 {
    width: 100%;
  }
  .home-text102 {
    width: 100%;
    font-size: 44px;
    line-height: 48px;
  }
  .home-text105 {
    width: 100%;
    font-size: 44px;
    line-height: 48px;
  }
  .home-text106 {
    color: rgb(58, 60, 63);
    font-size: 18px;
    font-family: Gotham-Book;
    font-weight: 300;
  }
  .home-frame4815971 {
    align-items: center;
    flex-direction: column-reverse;
  }
  .home-privacy {
    gap: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-image1 {
    width: 100%;
  }
  .home-enterprise-cards1 {
    width: 100%;
    margin-left: 0px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .home-excellence {
    margin-top: 0px;
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-unit);
    margin-bottom: 0px;
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .home-image2 {
    width: 100%;
  }
  .home-enterprise-cards2 {
    width: 100%;
    margin-left: 0px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .home-container4 {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-frame1171275357 {
    width: 100%;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-frame1171275349 {
    flex-direction: column;
  }
  .home-frame1171275302 {
    width: 100%;
  }
  .home-frame1171275333 {
    width: 100%;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-frame11712753361 {
    width: 100%;
    align-self: center;
    justify-content: center;
  }
  .home-image4 {
    width: 100%;
  }
  .home-frame1171275352 {
    width: 100%;
    flex-direction: column;
  }
  .home-frame1171275332 {
    width: 100%;
  }
  .home-frame1171275334 {
    width: 100%;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-frame11712753351 {
    width: 100%;
  }
  .home-frame1171275338 {
    width: 100%;
  }
  .home-mentions {
    width: 100%;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-tweets {
    width: 100%;
    flex-direction: column;
  }
  .home-frame1171275353 {
    width: 100%;
  }
  .home-frame-typography28 {
    width: 100%;
  }
  .home-text186 {
    width: 100%;
  }
  .home-frame-typography30 {
    width: 100%;
  }
  .home-frame-typography32 {
    width: 100%;
  }
  .home-frame1171275354 {
    width: 100%;
  }
  .home-frame-typography34 {
    width: 100%;
  }
  .home-frame-typography36 {
    width: 100%;
  }
  .home-frame-typography38 {
    width: 100%;
  }
  .home-frame1171275355 {
    width: 100%;
  }
  .home-frame-typography40 {
    width: 100%;
  }
  .home-frame-typography42 {
    width: 100%;
  }
  .home-frame-typography44 {
    width: 100%;
  }
}
