.blog-choosing-ada-solution-third-party-local-or-da-cs-container1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/neon-back-1500w.png");
}
 
.blog-choosing-ada-solution-third-party-local-or-da-cs-container2 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 1126px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  flex-direction: column;
}
 
.blog-choosing-ada-solution-third-party-local-or-da-cs-post {
  gap: 32px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.blog-choosing-ada-solution-third-party-local-or-da-cs-gatewaywirexcasestudy1 {
  width: 100%;
  height: auto;
  align-self: stretch;
  border-radius: 30px;
}
 
.blog-choosing-ada-solution-third-party-local-or-da-cs-text10 {
  gap: 32px;
  width: 100%;
  display: flex;
  max-width: 800px;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.blog-choosing-ada-solution-third-party-local-or-da-cs-container3 {
  gap: 16px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.blog-choosing-ada-solution-third-party-local-or-da-cs-tags {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
 
.blog-choosing-ada-solution-third-party-local-or-da-cs-text11 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 32px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700;
  line-height: 40px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-choosing-ada-solution-third-party-local-or-da-cs-text12 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Book;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 300;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.blog-choosing-ada-solution-third-party-local-or-da-cs-text13 {
  color: var(--dl-color-default-maintext);
  height: auto;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-stretch: normal;
}
 
@media(max-width: 1200px) {
  .blog-choosing-ada-solution-third-party-local-or-da-cs-gatewaywirexcasestudy1 {
    width: 100%;
    height: auto;
  }
  .blog-choosing-ada-solution-third-party-local-or-da-cs-text13 {
    color: var(--dl-color-default-maintext);
    font-size: 18px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 28px;
  }
}
 
@media(max-width: 991px) {
  .blog-choosing-ada-solution-third-party-local-or-da-cs-container2 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
}
 
@media(max-width: 767px) {
  .blog-choosing-ada-solution-third-party-local-or-da-cs-container2 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
}
 
@media(max-width: 550px) {
  .blog-choosing-ada-solution-third-party-local-or-da-cs-container2 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .blog-choosing-ada-solution-third-party-local-or-da-cs-post {
    width: 100%;
  }
  .blog-choosing-ada-solution-third-party-local-or-da-cs-gatewaywirexcasestudy1 {
    height: auto;
  }
}
