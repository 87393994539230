.indexer-container1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/neon-back-1500w.png");
}
 
.indexer-hero {
  gap: 24px;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-sixunits);
  flex-direction: column;
}
 
.indexer-frame1171275211 {
  gap: 24px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.indexer-text1 {
  color: rgb(0, 0, 0);
  width: 1126px;
  height: auto;
  font-size: 72px;
  font-style: Black;
  text-align: center;
  font-family: "Gotham-Ultra";
  font-weight: 900;
  line-height: 72px;
  font-stretch: normal;
  text-decoration: none;
}
 
.indexer-cubebitmin1 {
  width: 862px;
  height: 249px;
  z-index: 1;
}
 
.indexer-frame482057 {
  gap: 120px;
  width: 1126px;
  height: 166px;
  display: flex;
  padding: 48px;
  z-index: 2;
  overflow: hidden;
  box-shadow: 0px 4px 32px -12px rgba(0, 0, 0, 0.25) ;
  margin-top: -96px;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 2px 0 0 2px;
  border-radius: 29px;
  flex-direction: column;
  backdrop-filter: blur(64px);
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
}
 
.indexer-text6 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Medium;
  text-align: center;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 32px;
  font-stretch: normal;
  text-decoration: none;
}
 
.indexer-container2 {
  gap: 24px;
  width: 100%;
  height: auto;
  display: flex;
  max-width: 1126px;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
 
.indexer-container3 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
 
.indexer-container4 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
 
.indexer-scheme {
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.indexer-frame11712752841 {
  gap: 128px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
 
.indexer-ellipse51 {
  width: 7px;
  height: 64px;
}
 
.indexer-ellipse61 {
  width: 7px;
  height: 64px;
}
 
.indexer-frame11712752842 {
  gap: 128px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
 
.indexer-ellipse52 {
  width: 7px;
  height: 64px;
}
 
.indexer-ellipse62 {
  width: 7px;
  height: 64px;
}
 
.indexer-frame11712752843 {
  gap: 128px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
 
.indexer-ellipse53 {
  width: 7px;
  height: 64px;
}
 
.indexer-ellipse63 {
  width: 7px;
  height: 64px;
}
 
@media(max-width: 1200px) {
  .indexer-container1 {
    padding-bottom: 0px;
  }
  .indexer-hero {
    height: auto;
    position: relative;
    padding-top: var(--dl-space-space-fiveunits);
  }
  .indexer-text1 {
    color: rgb(0, 0, 0);
    font-size: 72px;
    font-family: Gotham-Ultra;
    font-weight: 900;
    line-height: 72px;
    text-decoration: none;
  }
  .indexer-text6 {
    color: rgb(0, 0, 0);
    font-size: 24px;
    font-family: Gotham-Medium;
    font-weight: 500;
    line-height: 32px;
    text-decoration: none;
  }
}
 
@media(max-width: 550px) {
  .indexer-container1 {
    gap: var(--dl-space-space-unit);
    padding-bottom: 0px;
  }
  .indexer-hero {
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .indexer-text1 {
    color: rgb(0, 0, 0);
    width: 100%;
    font-size: 48px;
    font-family: Gotham-Ultra;
    font-weight: 900;
    line-height: 48px;
    text-decoration: none;
  }
  .indexer-text2 {
    color: rgb(0, 0, 0);
    width: 100%;
    font-size: 48px;
    font-family: Gotham-Ultra;
    font-weight: 900;
    line-height: 48px;
    text-decoration: none;
  }
  .indexer-cubebitmin1 {
    width: 100%;
    height: 135px;
  }
  .indexer-frame482057 {
    width: 100%;
    height: auto;
    padding: var(--dl-space-space-oneandhalfunits);
  }
  .indexer-text6 {
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-decoration: none;
  }
  .indexer-container2 {
    width: 100%;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .indexer-container3 {
    flex-direction: column;
  }
  .indexer-container4 {
    flex-direction: column;
  }
}
