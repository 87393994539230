.node-sale-container1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 0px;
  background-size: cover;
  background-image: url("/external/neon-back-1500w.png");
}
 
.node-sale-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: 1126px;
  align-items: center;
  flex-direction: column;
}
 
.node-sale-hero {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
 
.node-sale-description {
  gap: var(--dl-space-space-halfunit);
  width: 40%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.node-sale-navlink1 {
  display: contents;
}
 
.node-sale-component16 {
  text-decoration: none;
}
 
.node-sale-rpc2 {
  width: auto;
  height: 100%;
}
 
.node-sale-live-sale {
  gap: 24px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: flex-end;
}
 
.node-sale-link {
  display: contents;
}
 
.node-sale-container2 {
  gap: 24px;
  display: flex;
  padding: 32px 56px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: 0px 8px 24px -20px rgba(127, 127, 127, 0.5) ;
  transition: 0.3s;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.30000001192092896);
}
 
.node-sale-container2:hover {
  box-shadow: 0px 8px 24px -20px #8950fa;
}
 
.node-sale-vector {
  width: 48px;
  height: 29px;
}
 
.node-sale-texts1 {
  gap: 8px;
  width: 886px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-direction: column;
}
 
.node-sale-title1 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.node-sale-text10 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 24px;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700px;
  line-height: 28px;
  font-stretch: normal;
  letter-spacing: 0em;
}
 
.node-sale-value {
  gap: 10px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  border-radius: 20px;
  justify-content: center;
  background-color: var(--dl-color-default-maininteractive);
}
 
.node-sale-text11 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 700;
  line-height: 18px;
  font-stretch: normal;
  text-decoration: none;
}
 
.node-sale-text12 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.node-sale-arrowright {
  width: 32px;
  height: 32px;
}
 
.node-sale-container3 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
 
.node-sale-legal-disclaimer {
  gap: 24px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: flex-end;
}
 
.node-sale-container4 {
  gap: 24px;
  display: flex;
  padding: 32px 56px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: 0px 8px 24px -20px rgba(127, 127, 127, 0.5) ;
  align-items: flex-start;
  border-radius: 29px;
  background-color: var(--dl-color-default-maininteractive);
}
 
.node-sale-texts2 {
  gap: 8px;
  width: 1014px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.node-sale-title2 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.node-sale-text13 {
  color: var(--dl-color-default-secondarytext);
  height: auto;
  font-size: 24px;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700px;
  line-height: 28px;
  font-stretch: normal;
  letter-spacing: 0em;
}
 
.node-sale-text14 {
  color: var(--dl-color-default-secondarytext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Book";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.node-sale-container5 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
 
.node-sale-frame1171275261 {
  gap: 24px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: flex-end;
}
 
.node-sale-frame481837 {
  gap: 24px;
  display: flex;
  padding: 32px 56px;
  position: relative;
  flex-grow: 1;
  box-shadow: 0px 8px 6px -20px rgba(127, 127, 127, 0.20000000298023224) ;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-radius: 29px;
  background-color: rgba(255, 255, 255, 0.30000001192092896);
}
 
.node-sale-frame1171275255 {
  gap: 8px;
  width: 1014px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.node-sale-frame1171275257 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.node-sale-text15 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 24px;
  font-style: normal;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 700px;
  line-height: 28px;
  font-stretch: normal;
  letter-spacing: 0em;
}
 
.node-sale-text16 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.node-sale-rocket1 {
  top: -40%;
  right: 0px;
  width: 217px;
  height: 217px;
  margin: auto;
  z-index: 1;
  position: absolute;
}
 
.node-sale-advisory {
  gap: 24px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 30px;
  background-size: cover;
  justify-content: flex-end;
  background-image: url("/external/node-sale-back-min-1200w.jpg");
}
 
.node-sale-container6 {
  gap: 24px;
  display: flex;
  padding: 32px 56px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: 0px 8px 24px -20px rgba(127, 127, 127, 0.5) ;
  align-items: flex-start;
  border-radius: 29px;
}
 
.node-sale-content2 {
  gap: 8px;
  width: 1014px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.node-sale-title3 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.node-sale-text17 {
  color: var(--dl-color-default-secondarytext);
  height: auto;
  font-size: 40px;
  font-style: Black;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 900;
  line-height: 56px;
  font-stretch: normal;
  text-decoration: none;
}
 
.node-sale-text21 {
  color: var(--dl-color-default-secondarytext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 700;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
.node-sale-navlink2 {
  display: contents;
}
 
.node-sale-component21 {
  text-decoration: none;
}
 
.node-sale-component23 {
  text-decoration: none;
}
 
.node-sale-testimonials {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
 
.node-sale-component27 {
  text-decoration: none;
}
 
@media(max-width: 1200px) {
  .node-sale-container1 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .node-sale-testimonials {
    gap: var(--dl-space-space-unit);
    width: 100%;
    flex-direction: row;
  }
}
 
@media(max-width: 767px) {
  .node-sale-testimonials {
    flex-direction: column;
  }
}
 
@media(max-width: 550px) {
  .node-sale-container1 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .node-sale-content1 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .node-sale-hero {
    flex-direction: column-reverse;
  }
  .node-sale-description {
    gap: var(--dl-space-space-unit);
    width: 100%;
  }
  .node-sale-rpc2 {
    width: 100%;
    height: auto;
  }
  .node-sale-container2 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
  }
  .node-sale-vector {
    display: none;
  }
  .node-sale-texts1 {
    width: 100%;
  }
  .node-sale-title1 {
    flex-direction: column-reverse;
  }
  .node-sale-container3 {
    gap: var(--dl-space-space-unit);
    flex-direction: column;
    justify-content: unset;
  }
  .node-sale-container4 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .node-sale-texts2 {
    width: 100%;
  }
  .node-sale-container5 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: unset;
  }
  .node-sale-frame481837 {
    width: 100%;
    flex-direction: column-reverse;
  }
  .node-sale-frame1171275255 {
    width: 100%;
  }
  .node-sale-rocket1 {
    position: static;
  }
  .node-sale-container6 {
    gap: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .node-sale-content2 {
    gap: var(--dl-space-space-unit);
    width: 100%;
  }
  .node-sale-text17 {
    font-size: 28px;
    line-height: 32px;
  }
  .node-sale-testimonials {
    flex-direction: column;
  }
}
