.card-video-background-container {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  border-radius: 29px;
  flex-direction: column;
}
 
.card-video-background-video {
  width: 100%;
  height: auto;
  object-fit: cover;
}
 
.card-video-background-content {
  gap: 120px;
  top: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 48px;
  overflow: hidden;
  position: absolute;
  flex-grow: 1;
  box-shadow: 0px 4px 32px -12px rgba(0, 0, 0, 0.25) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 29px;
  flex-direction: column;
  justify-content: center;
}
 
.card-video-background-texts {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.card-video-background-text1 {
  color: var(--dl-color-default-secondarytext);
  height: auto;
  font-size: 32px;
  align-self: stretch;
  font-style: Black;
  text-align: left;
  font-family: "Gotham-Bold";
  font-weight: 900;
  line-height: 40px;
  font-stretch: normal;
  text-decoration: none;
}
 
.card-video-background-text2 {
  color: var(--dl-color-default-secondarytext);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
 
@media(max-width: 550px) {
  .card-video-background-video {
    min-height: 300px;
  }
  .card-video-background-content {
    padding: var(--dl-space-space-oneandhalfunits);
  }
}
