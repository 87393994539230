.card-branding-container2 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: auto;
  display: flex;
  padding: 48px;
  overflow: hidden;
  flex-grow: 1;
  transition: 0.3s;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.card-branding-container2:hover {
  background-color: var(--dl-color-default-secondarytext);
}
 
.card-branding-download {
  gap: 89px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: flex-end;
}
 
.card-branding-download-button {
  gap: 10px;
  display: flex;
  padding: 16px;
  align-items: flex-start;
  border-radius: 30px;
  background-color: var(--dl-color-default-maininteractive);
}
 
.card-branding-trayarrowdown {
  width: 24px;
  height: 24px;
}
 
.card-branding-image {
  width: auto;
  max-height: 57px;
  object-fit: cover;
}
 
.card-branding-texts {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.card-branding-text1 {
  fill: var(--dl-color-default-maintext);
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Gotham-Medium";
  font-weight: 500;
  line-height: 18px;
  font-stretch: normal;
  text-decoration: none;
}
 
.card-branding-text2 {
  color: var(--dl-color-default-maintext);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Ultra;
  text-align: left;
  font-family: "Gotham-Black";
  font-weight: 400;
  line-height: 32px;
  font-stretch: normal;
  text-decoration: none;
}
 
.card-brandingroot-class-name {
  height: auto;
  background-color: var(--dl-color-default-maininteractive);
}
 
.card-brandingroot-class-name1 {
  height: auto;
  background-color: var(--dl-color-default-secondarytext);
}
 
.card-brandingroot-class-name2 {
  height: auto;
  background-color: var(--dl-color-default-secondarytext);
}
 
.card-brandingroot-class-name3 {
  height: auto;
  background-color: var(--dl-color-default-secondarytext);
}
 
.card-brandingroot-class-name4 {
  height: auto;
  background-color: var(--dl-color-default-secondarytext);
}
 
.card-brandingroot-class-name6 {
  height: auto;
  background-color: var(--dl-color-default-secondarytext);
}
 
.card-brandingroot-class-name7 {
  height: auto;
  background-color: var(--dl-color-default-secondarytext);
}
 
.card-brandingroot-class-name8 {
  height: auto;
  background-color: var(--dl-color-default-secondarytext);
}
